define("client/models/category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    category_name: _emberData.default.attr('string'),
    category_image: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    //relation	
    category_parent: _emberData.default.belongsTo('category', {
      inverse: 'children',
      async: true
    }),
    children: _emberData.default.hasMany('category', {
      inverse: 'category_parent',
      async: true
    }),
    // itemcategorynum: DS.hasMany('itemcategorydetail', {async: true}),
    // storenum: DS.belongsTo('store', {async: true}),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});