define("client/pods/checkout/route", ["exports", "axios", "client/mixins/checkout/checkout-list", "client/mixins/address/address-logic"], function (_exports, _axios, _checkoutList, _addressLogic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkoutList.default, _addressLogic.default, {
    authObject: Ember.inject.service('session'),
    queryParams: {
      product: {
        refreshModel: true
      },
      qty: {
        refreshModel: true
      },
      nt: {
        refreshModel: true
      },
      vt: {
        refreshModel: true
      },
      vr1: {
        refreshModel: true
      },
      vr2: {
        refreshModel: true
      },
      tr: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      //should redirect to index if checkout list empty. also, moved redirect if not logged in to beforemodel
      if (this.get('authObject').get('isAuthenticated') === false) {
        this.transitionTo("index");
      }
    },
    model: function model(param) {
      return param;
    },
    setupController: function setupController(controller, model) {
      var self = this,
          newtempaddress = Ember.A();
      var newAddress = Ember.Object.extend({
        name: null,
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        country: null,
        postal_code: null
      });
      newtempaddress.pushObject(newAddress);
      controller.setProperties({
        domain: self.store.adapterFor('application').get('imagedomainHost'),
        changeaddress: 'changeaddress',
        createnewaddress: 'createnewaddress',
        checkoutaddressplease: 'checkoutaddressplease',
        seecheckout: false,
        nopromo: true,
        ispromocode: false,
        loadingPage: true,
        discountpercentage: 0,
        disc: 0,
        grandtotal: 0,
        promocode: null,
        model: model,
        newaddress: newtempaddress
      });

      if (model.tr) {
        controller.set('seecheckout', true);
      }

      self.store.query('country', {
        sort: 'nicename ASC'
      }).then(function (countries) {
        controller.set('countryList', countries);
      });
      self.getItemList();
      self.getInfo();

      self._super();
    },
    actions: {
      chooseaddress: function chooseaddress(address) {
        this.chooseaddress(address);
      },
      createaddress: function createaddress(address) {
        this.createaddress(address);
      },
      readypay: function readypay() {
        this.readypay();
      },
      promoCode: function promoCode(code) {
        this.promoCode(code);
      },
      promoCancel: function promoCancel() {
        var controller = this.get('controller');
        controller.setProperties({
          promocode: null,
          nopromo: true,
          ispromocode: false,
          disc: 0,
          discountpercentage: 0,
          disablebutton: false,
          code: null
        });
      }
    }
  });

  _exports.default = _default;
});