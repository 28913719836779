define("client/mixins/w3o-users-roles/role-manager-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    roleName: '',
    appId: '',
    roleId: '',
    appRoleModel: '',
    actionModel: '',
    // actionList: [],
    authObject: Ember.inject.service('session'),
    model: function model() {
      var _this = this;

      Ember.$.ajax({
        type: "POST",
        data: {
          app: this.get('appId')
        },
        url: this.store.adapterFor('application').get('namespace') + '/allowedroutes/getParentRoutes',
        success: function success(response) {
          return _this.controller.setProperties({
            model: response,
            loading: false
          });
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
    },
    setupController: function setupController() {
      var controller = this.get('controller');
      controller.set('loading', true);
      controller.set('userRoleModel', '');
      controller.set('appId', this.get('appId'));
      controller.set('role', '');
      controller.set('roleName', '');
      controller.set('roleSelected', false);
      controller.set('groupSelected', false);
      controller.set('routeChecker', Ember.A());
      controller.set('routes', '');
      controller.set('childRoutes', '');
      controller.set('headerRoute', '');
      controller.set('listPage', 1);
      controller.set('listPageChild', 1);
      controller.set('listPageModal', 1);
      controller.set('totalPage', 1);
      controller.set('totalPageChild', 1);
      controller.set('routeContainer', '');
      controller.set('routeContainerChild', '');
      controller.set('limit', 10);
      this.store.findRecord('application', this.get('appId')).then(function (app) {
        controller.set('appName', app.get('appName'));
      });
      this.store.query('role', {
        application: this.get('appId')
      }).then(function (roles) {
        controller.set('roleModel', roles);
        controller.set('loading', false);
      });
    },
    setRole: function setRole(params) {
      var routeIdCon = Ember.A();
      var controller = this.get('controller');
      controller.set('headerRoute', '');
      controller.set('groupSelected', false);
      controller.set('routeChecker', Ember.A());
      controller.set('roleSelected', true);
      this.set('roleId', params.id);
      controller.set('createRole', false);
      controller.set('roleId', params.id);
      controller.set('roleName', params.get('roleName')); // KODINGAN PAKE DB BARU
      // ROUTE CHECKER

      this.store.query('roleroute', {
        role: params.id
      }).then(function (roleroutes) {
        roleroutes.forEach(function (roleroute) {
          routeIdCon.pushObject(roleroute.get('allowedroute.content.id'));
        });
        controller.set('routeChecker', routeIdCon);
      });
    },
    getRoutes: function getRoutes(params) {
      var controller = this.get('controller');
      var temp = Ember.A();
      var checked = false;
      controller.set('routeContainer', params);
      controller.set('groupSelected', false);

      if (params) {
        Ember.$.ajax({
          type: "POST",
          data: {
            parent: params.id,
            page: controller.get('listPage'),
            limit: controller.get('limit')
          },
          url: this.store.adapterFor('application').get('namespace') + '/allowedroutes/getChildRoutes',
          success: function success(response) {
            var Variable = Ember.Object.extend({
              id: null,
              application: null,
              parent: null,
              children: null,
              routeName: null,
              pageName: null,
              dynamicValue: 'none',
              checked: false
            });
            response.routes.forEach(function (route) {
              checked = false;

              if (controller.get('routeChecker').indexOf(route.id.toString()) > -1) {
                checked = true;
              }

              temp.pushObject(Variable.create({
                id: route.id,
                application: route.application,
                parent: route.parent,
                children: route.children,
                routeName: route.routeName,
                pageName: route.pageName,
                dynamicValue: route.dynamicValue,
                checked: checked
              }));
            });

            if (response.exceedTotal) {
              controller.set('listPage', 1);
            }

            controller.set('totalPage', response.totalPage);
            controller.set('routes', temp);
            controller.set('groupSelected', true);
            Ember.$('.collapse').on('show.bs.collapse', function () {
              Ember.$('.collapse.in').collapse('hide');
            });
          },
          error: function error(xhr, status, _error2) {
            console.log('Error ' + _error2);
          }
        });
      }
    },
    getChildRoutes: function getChildRoutes(params) {
      var controller = this.get('controller');
      var temp = Ember.A();
      var checked = false;
      var notEqual = false;

      if (controller.get('routeContainerChild') !== params) {
        notEqual = true;
        controller.set('listPageChild', 1);
      }

      controller.set('routeContainerChild', params);
      /*if(controller.get('childRoutes')){
      	controller.set('childRoutes', '');
      }
      else{*/

      Ember.$.ajax({
        type: "POST",
        data: {
          parent: params.id,
          page: controller.get('listPageChild'),
          limit: controller.get('limit')
        },
        url: this.store.adapterFor('application').get('namespace') + '/allowedroutes/getChildRoutes',
        success: function success(response) {
          var Variable = Ember.Object.extend({
            id: null,
            application: null,
            parent: null,
            routeName: null,
            pageName: null,
            dynamicValue: 'none',
            checked: false
          });
          response.routes.forEach(function (route) {
            checked = false;

            if (controller.get('routeChecker').indexOf(route.id.toString()) > -1) {
              checked = true;
            }

            temp.pushObject(Variable.create({
              id: route.id,
              application: route.application,
              parent: route.parent,
              routeName: route.routeName,
              pageName: route.pageName,
              dynamicValue: route.dynamicValue,
              checked: checked
            }));
          });

          if (response.exceedTotal) {
            controller.set('listPage', 1);
          }

          controller.set('childRoutes', temp);
          controller.set('totalPageChild', response.totalPage);

          if (controller.get('listPageChild') > controller.get('totalPageChild') || notEqual) {
            controller.set('listPageChild', 1);
          }

          Ember.$('.collapse').on('show.bs.collapse', function () {
            Ember.$('.collapse.in').collapse('hide');
          });
        },
        error: function error(xhr, status, _error3) {
          console.log('Error ' + _error3);
        }
      }); // }
    },
    changeLimit: function changeLimit() {
      var controller = this.get('controller');
      this.send('getRoutes', controller.get('routeContainer'));
    },
    prevList: function prevList(type) {
      var controller = this.get('controller');

      if (type === 'route') {
        controller.set('listPage', controller.get('listPage') - 1);
        this.send('getRoutes', controller.get('routeContainer'));
      } else if (type === 'child') {
        controller.set('listPageChild', controller.get('listPageChild') - 1);
        this.send('getChildRoutes', controller.get('routeContainerChild'));
      }
    },
    nextList: function nextList(type) {
      var controller = this.get('controller');

      if (type === 'route') {
        controller.set('listPage', controller.get('listPage') + 1);
        this.send('getRoutes', controller.get('routeContainer'));
      } else if (type === 'child') {
        controller.set('listPageChild', controller.get('listPageChild') + 1);
        this.send('getChildRoutes', controller.get('routeContainerChild'));
      }
    },
    goTo: function goTo(param) {
      this.transitionTo('users-roles.role-manager.app.' + param, this.get('appId'));
    }
  });

  _exports.default = _default;
});