define("client/models/variantqty", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    qty: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    isdelete: _emberData.default.attr('boolean'),
    isactive: _emberData.default.attr('boolean'),
    //relation	
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    variant1num: _emberData.default.belongsTo('variant', {
      async: true
    }),
    variant2num: _emberData.default.belongsTo('variant', {
      async: true
    }),
    variantdetail1num: _emberData.default.belongsTo('variantdetail', {
      async: true
    }),
    variantdetail2num: _emberData.default.belongsTo('variantdetail', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});