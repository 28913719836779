define("client/mixins/w3o-users-roles/check-user-role", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_applicationRouteMixin.default, {
    renderFlag: false,
    setActions: Ember.A(),

    /*
    	Inject session service as authObject
    */
    authObject: Ember.inject.service('session'),

    /*
    	Check whether the current route access is allowed or not
    */
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);
      /*
      	Initialize variables
      */


      var app = this.get('routeName').split('.');
      var dynamicValue = 'none';
      /*
      	Set dynamic param if the current route has dynamic param
      	else, set as 'none'
      */

      if (this.get('dynamicParam')) {
        dynamicValue = this.get('dynamicParam')[this.get('routeName')][this.get('dynamicKey')];

        if (!dynamicValue) {
          if (this.get('paramContainer')) {
            dynamicValue = this.get('dynamicParam')[this.get('paramContainer')][this.get('dynamicKey')];
          } else {
            dynamicValue = 'none';
          }
        }
      }
      /*
      	Check whether the route access is allowed via API call to server
      */


      Ember.$.ajax({
        type: "POST",
        data: {
          userId: this.get('authObject.data.user.id'),
          routeName: this.get('routeName'),
          dynamicValue: dynamicValue,
          actionList: JSON.stringify(this.get('actionList'))
        },
        url: this.store.adapterFor('application').get('namespace') + '/allowedroutes/checkAccess',
        success: function success(response) {
          _this.setAllowedActions(response, dynamicValue, app);
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
    },

    /*
    	While waiting for data, the template will render loading page
    	After data has been loaded, the template will render the route
    */
    renderTemplate: function renderTemplate() {
      if (this.get('renderFlag')) {
        try {
          this.render(this.get('routeName'));
        } catch (err) {// Ignore render controller error.
        }
      } else {
        this.render('loading');
      }
    },

    /*
    	Set allowed actions and send it back to route
    	If the status is false, redirect to unauthorized page
    */
    setAllowedActions: function setAllowedActions(response, dynamicValue, app) {
      var getResponse = response;
      var temp = [];

      if (getResponse.status) {
        if (getResponse.setActions.length > 0) {
          var Variable = Ember.Object.extend({
            action: null,
            varName: null,
            status: false
          });
          response.setActions.forEach(function (action, index) {
            temp.push(Variable.create({
              action: action.action,
              varName: action.varName,
              status: action.status,
              varValue: action.varValue
            }));
          });
          this.set('setActions', temp);
        }

        this.set('renderFlag', true);
        this.renderTemplate();
      } else {
        this.transitionTo("".concat(app[0], ".unauthorized"), this.get('routeName'), dynamicValue);
      }
    }
  });

  _exports.default = _default;
});