define("client/pods/components/w3o-users-roles/google-recaptcha/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sAA2uNF2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"recapt\"],[11,\"class\",\"g-recaptcha d-flex justify-content-center\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/google-recaptcha/template.hbs"
    }
  });

  _exports.default = _default;
});