define("client/models/price", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    item_price: _emberData.default.attr('number'),
    date_active: _emberData.default.attr('date'),
    date_inactive: _emberData.default.attr('date'),
    active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isdelete: _emberData.default.attr('boolean'),
    //relation	
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});