define("client/pods/components/w3o-users-roles/header-main/component", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _authenticatedRouteMixin, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_authenticatedRouteMixin.default, _applicationRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    actions: {
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      },
      showHideSidebar: function showHideSidebar() {
        // check sidebar shown/hidden
        var flag = Ember.$('.show-sidebar');

        if (flag.length === 0) {
          Ember.$('#headernavdesktop-accordion').addClass('show-sidebar');
          Ember.$('#headernavdesktop-accordion').removeClass('hide-sidebar');
          Ember.$('.navbar').css('box-shadow', ' 0 4px 6px 0 hsla(0,0%,0%,0.2)');
          Ember.$('.sidebar-mask').show();
        } else {
          Ember.$('.show-sidebar').addClass('hide-sidebar');
          Ember.$('.show-sidebar').removeClass('show-sidebar');
          Ember.$('.navbar').css('box-shadow', '');
          Ember.$('.sidebar-mask').hide();
        }
      },
      hideSidebarOnClick: function hideSidebarOnClick() {
        // hide sidebar on click
        Ember.$('.show-sidebar').addClass('hide-sidebar');
        Ember.$('.show-sidebar').removeClass('show-sidebar');
        Ember.$('.navbar').css('box-shadow', '');
        Ember.$('.sidebar-mask').hide();
      }
    }
  });

  _exports.default = _default;
});