define("client/mixins/category/category-list", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController: function setupController() {},
    getCategorylist: function getCategorylist() {
      var self = this;
      var controller = self.get('controller');
      (0, _axios.default)({
        method: "POST",
        data: {
          isdelete: false
        },
        url: self.store.adapterFor('application').get('namespace') + '/category/getCategorylist'
      }).then(function (response) {
        controller.set('category', response.data.categories);
      }).catch(function (error) {
        console.log('Error ' + error);
      });
    }
  });

  _exports.default = _default;
});