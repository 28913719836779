define("client/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c8jtL9ea",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"header\"],[[\"setSession\",\"invalidateSession\",\"getSearch\",\"session\",\"search\",\"customer\",\"pathphoto\",\"domain\",\"totalcart\"],[[27,\"route-action\",[\"setSession\"],null],[27,\"route-action\",[\"invalidateSession\"],null],[27,\"route-action\",[\"getSearch\"],null],[23,[\"session\"]],[23,[\"search\"]],[23,[\"customer\"]],[23,[\"pathphoto\"]],[23,[\"domain\"]],[23,[\"totalcart\"]]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[1,[27,\"component\",[\"footer\"],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});