define("client/mixins/w3o-users-roles/invalidate-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authObject: Ember.inject.service('session'),
    invalidateSession: function invalidateSession() {
      //record logout      
      Ember.$.ajax({
        type: "POST",
        data: {
          userId: this.get('authObject.data.user.id')
        },
        url: this.store.adapterFor('application').get('namespace') + '/auths/trackLogout',
        success: function success(response) {
          console.log(response);
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
      /*this.get('authObject').set('data.authenticated.user.locale', null);
      this.get('authObject').set('data.authenticated.user.firstName', null);
      this.get('authObject').set('data.authenticated.user.lastName', null);
      this.get('authObject').set('data.authenticated.user.profilePicture', null);*/

      this.get('authObject').set('data.user', null);
      this.get('authObject').set('data.lastActivity', null);

      if (this.get('authObject.data.blobURL')) {
        window.URL = window.URL || window.webkitURL;
        window.URL.revokeObjectURL(this.get('authObject.data.blobURL'));
        this.get('authObject').set('data.blobURL', null);
      }

      this.get('authObject').invalidate();
    }
  });

  _exports.default = _default;
});