define("client/pods/components/w3o-users-roles/assign-paging/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      doSort: function doSort(param) {
        this.doSort(param);
      },
      doSearch: function doSearch() {
        this.doSearch();
      },
      changeColumn: function changeColumn(param) {
        this.changeColumn(param);
      },
      changeLimit: function changeLimit() {
        this.changeLimit();
      },
      prevList: function prevList() {
        this.prevList();
      },
      nextList: function nextList() {
        this.nextList();
      },
      unassignGroup: function unassignGroup(param) {
        this.unassignGroup(param);
      },
      assignGroup: function assignGroup(param) {
        this.assignGroup(param);
      },
      unassignUser: function unassignUser(param) {
        this.unassignUser(param);
      },
      assignUser: function assignUser(param) {
        this.assignUser(param);
      }
    }
  });

  _exports.default = _default;
});