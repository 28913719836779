define("client/mixins/w3o-users-roles/create-edit-appaccess", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    appId: '',
    authObject: Ember.inject.service('session'),
    edit: false,
    setupController: function setupController() {
      var controller = this.get('controller');
      var model = this.get('currentModel');
      controller.set('model', model);
      controller.set('appId', this.get('appId'));
      this.store.query('role', {
        application: this.get('appId')
      }).then(function (roles) {
        controller.set('roleList', roles);
      });
      this.store.findRecord('application', this.get('appId')).then(function (app) {
        controller.set('model.application', app);
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$('#access').removeClass('active');
        Ember.$('#assign').removeClass('active');
        Ember.$('#role').removeClass('active');
        Ember.$('#route').removeClass('active');
        Ember.$('#app-access').addClass('active');
      });
    },
    saveAppAccess: function saveAppAccess(model) {
      var flag = 0;
      var self = this;
      var controller = this.get('controller');

      if (Ember.isEmpty(model.get('role.content'))) {
        Ember.$("#roleForm").addClass("has-error");
        Ember.$("#roleIsEmpty").removeClass("d-none");
        flag++;
      }

      if (flag === 0) {
        controller.set('disableSubmit', true);
        model.save().then(function () {
          self.transitionTo('users-roles.role-manager.app.app-access', self.get('appId'));
        });
      }
    },
    goTo: function goTo(param) {
      this.transitionTo('users-roles.role-manager.app.' + param, this.get('appId'));
    }
  });

  _exports.default = _default;
});