define("client/pods/register/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    question: '',
    //this provides a space for new model
    model: function model() {
      return this.store.createRecord('user');
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        success: false
      });
      this.store.query('country', {
        sort: 'nicename ASC'
      }).then(function (countries) {
        controller.set('countryList', countries);
      });
    },
    successRegister: function successRegister() {
      var controller = this.get('controller');
      this.transitionTo("index");
    },
    actions: {
      createUser: function createUser(model) {
        var self = this;
        var controller = this.get('controller');
        var flag = 0; //split full name into first and last name

        var firstName = controller.get('fullName').split(' ').slice(0, 1).join(' ');
        var lastName = controller.get('fullName').split(' ').slice(1).join(' ');
        var fullname = controller.get('fullName');

        if (!firstName) {
          firstName = lastName;
          lastName = null;
        }

        if (Ember.isEmpty(controller.get('fullName'))) {
          controller.set('message', 'Please input your full name!');
          flag++;
          return;
        }

        if (Ember.isEmpty(controller.get('countryid'))) {
          controller.set('message', 'Please input your country!');
          flag++;
          return;
        }

        if (Ember.isEmpty(model.get('email'))) {
          controller.set('message', 'Please input your email!');
          flag++;
          return;
        } else {
          var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

          if (!regexEmail.test(model.get('email').toLowerCase())) {
            controller.set('message', 'Sorry, email format is not correct');
            flag++;
            return;
          }
        }

        if (Ember.isEmpty(model.get('password'))) {
          controller.set('message', 'Please input your password!');
          flag++;
          return;
        } else {
          var regexPass = /.{6,}/;

          if (!regexPass.test(model.get('password'))) {
            controller.set('message', 'Password must be at least 6 characters!');
            flag++;
            return;
          }
        }

        if (controller.get('confirmpassword') != model.get('password')) {
          controller.set('message', 'Password does not match!');
          flag++;
          return;
        }

        if (flag === 0) {
          console.log('Ready to Register');
          controller.set('message', null);
          Ember.$.ajax({
            url: '/api/v1/users',
            type: 'POST',
            data: JSON.stringify({
              user: {
                country: controller.get('countryid'),
                firstName: firstName,
                lastName: lastName,
                fullname: fullname,
                password: model.get('password'),
                email: model.get('email').trim().toLowerCase(),
                status: 'Disabled'
              }
            }),
            contentType: 'application/json',
            success: function success(response) {
              controller.set('success', true);
              setTimeout(function () {
                self.successRegister();
              }, 3000);
            },
            error: function error(xhr, status, _error) {
              controller.set('message', xhr.responseText);
              setTimeout(function () {
                controller.set('message', null);
              }, 3000);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});