define("client/mixins/w3o-users-roles/create-edit-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authObject: Ember.inject.service('session'),
    create: false,
    setupController: function setupController() {
      var controller = this.get('controller');
      var model = this.get('currentModel');
      controller.set('model', model);
      controller.set('groupModel', this.store.findAll('group'));
      controller.set('applicationModel', this.store.findAll('application'));
      controller.set('secretQuestionModel', this.store.findAll('secretquestion'));
      controller.set('hasSelect', false);
      controller.set('countryList', this.store.query('country', {
        sort: 'nicename ASC'
      }));
    },
    getInfo: function getInfo(param) {
      var self = this;
      var controller = this.get('controller');
      controller.set('model.state', '-');
      Ember.$("#stateForm").removeClass('has-error');
      Ember.$('#glyphState').addClass("d-none");
      Ember.$('#stateIsEmpty').addClass("d-none");
      Ember.$.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getAddressByPostalCodeFromGoThere',
        success: function success(response) {
          controller.set('model.address', response.value);

          if (response.value) {
            Ember.$("#addressForm").removeClass('has-error');
            Ember.$('#glyphAddress').addClass("d-none");
            Ember.$('#addressIsEmpty').addClass("d-none");
          }
        },
        error: function error(xhr, status, _error) {
          console.log('Error ' + _error);
        }
      });
      Ember.$.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getAddressLineByPostalCodeFromGoThere',
        success: function success(response) {
          controller.set('model.address1', response.value);

          if (response.value) {
            Ember.$("#address1Form").removeClass('has-error');
            Ember.$('#glyphAddress1').addClass("d-none");
            Ember.$('#address1IsEmpty').addClass("d-none");
          }
        },
        error: function error(xhr, status, _error2) {
          console.log('Error ' + _error2);
        }
      });
      Ember.$.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getCountryNameByPostalCodeFromGoThere',
        success: function success(response) {
          controller.set('model.city', response.value);

          if (response.value) {
            Ember.$("#cityForm").removeClass('has-error');
            Ember.$('#glyphCity').addClass("d-none");
            Ember.$('#cityIsEmpty').addClass("d-none");
          }
        },
        error: function error(xhr, status, _error3) {
          console.log('Error ' + _error3);
        }
      });
      Ember.$.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getCountryNameCodeByPostalCodeFromGoThere',
        success: function success(response) {
          self.store.queryRecord('country', {
            iso: response.value
          }).then(function (country) {
            controller.set('model.country', country);

            if (response.value) {
              Ember.$("#countryForm").removeClass('has-error');
              Ember.$('#glyphCountry').addClass("d-none");
              Ember.$('#countryIsEmpty').addClass("d-none");
            }
          });
        },
        error: function error(xhr, status, _error4) {
          console.log('Error ' + _error4);
        }
      });
    },
    saveUser: function saveUser(model) {
      var self = this;
      var flag = 0;
      var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      var regexPass = /.{6,}/;
      var controller = this.get('controller');
      var scrolled = false;

      if (Ember.isEmpty(model.get('firstName'))) {
        Ember.$("#firstNameForm").addClass("has-error");
        Ember.$("#firstNameIsEmpty").removeClass("d-none");
        Ember.$("#glyphFirstName").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#firstNameForm").offset().top - (Ember.$("#lastNameForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('lastName'))) {
        Ember.$("#lastNameForm").addClass("has-error");
        Ember.$("#lastNameIsEmpty").removeClass("d-none");
        Ember.$("#glyphLastName").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#lastNameForm").offset().top - (Ember.$("#lastNameForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('email'))) {
        Ember.$("#emailForm").addClass("has-error");
        Ember.$("#emailIsEmpty").removeClass("d-none");
        Ember.$("#glyphEmail").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#emailForm").offset().top - (Ember.$("#emailForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      } else if (!regexEmail.test(model.get('email'))) {
        Ember.$("#emailForm").addClass("has-error");
        Ember.$("#emailRegexError").removeClass("d-none");
        Ember.$("#glyphEmail").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#emailForm").offset().top - (Ember.$("#emailForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('phone'))) {
        Ember.$("#phoneForm").addClass("has-error");
        Ember.$("#phoneIsNaN").removeClass("d-none");
        Ember.$("#glyphPhone").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#phoneForm").offset().top - (Ember.$("#phoneForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('postalCode'))) {
        Ember.$("#postalCodeForm").addClass("has-error");
        Ember.$("#postalCodeIsEmpty").removeClass("d-none");
        Ember.$("#glyphPostalCode").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#phoneForm").offset().top - (Ember.$("#phoneForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('address'))) {
        Ember.$("#addressForm").addClass("has-error");
        Ember.$("#addressIsEmpty").removeClass("d-none");
        Ember.$("#glyphAddress").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#addressForm").offset().top - (Ember.$("#addressForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('address1'))) {
        Ember.$("#address1Form").addClass("has-error");
        Ember.$("#address1IsEmpty").removeClass("d-none");
        Ember.$("#glyphAddress1").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#address1Form").offset().top - (Ember.$("#address1Form").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('city'))) {
        Ember.$("#cityForm").addClass("has-error");
        Ember.$("#cityIsEmpty").removeClass("d-none");
        Ember.$("#glyphCity").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#cityForm").offset().top - (Ember.$("#cityForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('state'))) {
        Ember.$("#stateForm").addClass("has-error");
        Ember.$("#stateIsEmpty").removeClass("d-none");
        Ember.$("#glyphState").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#stateForm").offset().top - (Ember.$("#stateForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('country.content'))) {
        Ember.$("#countryForm").addClass("has-error");
        Ember.$("#countryIsEmpty").removeClass("d-none");
        Ember.$("#glyphCountry").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#countryForm").offset().top - (Ember.$("#countryForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('password'))) {
        Ember.$("#passwordForm").addClass("has-error");
        Ember.$("#passwordIsEmpty").removeClass("d-none");
        Ember.$("#glyphPassword").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#passwordForm").offset().top - (Ember.$("#passwordForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      } else if (!regexPass.test(model.get('password'))) {
        Ember.$("#passwordForm").addClass("has-error");
        Ember.$("#passwordValidation").removeClass("d-none");
        Ember.$("#glyphPassword").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#passwordForm").offset().top - (Ember.$("#passwordForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('passwordRepeat'))) {
        Ember.$("#passwordRepeatForm").addClass("has-error");
        Ember.$("#passwordRepeatIsEmpty").removeClass("d-none");
        Ember.$("#glyphPasswordRepeat").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#passwordRepeatForm").offset().top - (Ember.$("#passwordRepeatForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      } else if (model.get('password') !== model.get('passwordRepeat')) {
        Ember.$("#passwordRepeat").addClass("has-error");
        Ember.$("#passwordIsNotSame").removeClass("d-none");
        Ember.$("#glyphPasswordRepeat").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#passwordRepeat").offset().top - (Ember.$("#passwordRepeat").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('secretquestion.content'))) {
        Ember.$("#questionForm").addClass("has-error");
        Ember.$("#questionIsEmpty").removeClass("d-none");
        Ember.$("#glyphQuestion").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#questionForm").offset().top - (Ember.$("#questionForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      } else {
        Ember.$("#questionForm").removeClass('has-error');
        Ember.$('#glyphQuestion').addClass('d-none');
        Ember.$('#questionIsEmpty').addClass('d-none');
      }

      if (Ember.isEmpty(model.get('answer'))) {
        Ember.$("#answerForm").addClass("has-error");
        Ember.$("#answerIsEmpty").removeClass("d-none");
        Ember.$("#glyphAnswer").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#answerForm").offset().top - (Ember.$("#answerForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('answerRepeat'))) {
        Ember.$("#answerRepeatForm").addClass("has-error");
        Ember.$("#answerRepeatIsEmpty").removeClass("d-none");
        Ember.$("#glyphAnswerRepeat").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#answerRepeatForm").offset().top - (Ember.$("#answerRepeatForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      } else if (model.get('answer') !== model.get('answerRepeat')) {
        Ember.$("#answerRepeatForm").addClass("has-error");
        Ember.$("#answerIsNotSame").removeClass("d-none");
        Ember.$("#glyphAnswerRepeat").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#answerRepeatForm").offset().top - (Ember.$("#answerRepeatForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (this.get('create') && Ember.isEmpty(controller.get('groupCon'))) {
        Ember.$("#groupForm").addClass("has-error");
        Ember.$("#groupIsEmpty").removeClass("d-none");
        Ember.$("#glyphGroup").removeClass("d-none");
        flag++;

        if (!scrolled) {
          Ember.$('html, body').animate({
            scrollTop: Ember.$("#groupForm").offset().top - (Ember.$("#groupForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      } else {
        Ember.$("#groupForm").removeClass("has-error");
        Ember.$("#glyphGroup").addClass("d-none");
        Ember.$("#groupIsEmpty").addClass("d-none");
      }

      Ember.$('#firstNameForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#firstNameForm").removeClass('has-error');
          Ember.$('#glyphFirstName').addClass('d-none');
          Ember.$('#firstNameIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#lastNameForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#lastNameForm").removeClass('has-error');
          Ember.$('#glyphLastName').addClass('d-none');
          Ember.$('#lastNameIsEmpty').addClass('d-none');
        }
      });
      /*Ember.$('#countryCodeForm input').blur(function()
      {
          if( this.value !== '') {
              Ember.$("#countryCodeForm").removeClass('has-error');
              Ember.$('#glyphCountryCode').addClass('d-none');
              Ember.$('#countryCodeIsEmpty').addClass('d-none');
              Ember.$('#countryCodeIsNaN').addClass('d-none');
          }
      });*/

      Ember.$('#postalCodeForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#postalCodeForm").removeClass('has-error');
          Ember.$('#glyphPostalCode').addClass("d-none");
          Ember.$('#postalCodeIsEmpty').addClass("d-none");
        }
      });
      Ember.$('#addressForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#addressForm").removeClass('has-error');
          Ember.$('#glyphAddress').addClass("d-none");
          Ember.$('#addressIsEmpty').addClass("d-none");
        }
      });
      Ember.$('#address1Form input').blur(function () {
        if (this.value !== '') {
          Ember.$("#address1Form").removeClass('has-error');
          Ember.$('#glyphAddress1').addClass("d-none");
          Ember.$('#address1IsEmpty').addClass("d-none");
        }
      });
      Ember.$('#cityForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#cityForm").removeClass('has-error');
          Ember.$('#glyphCity').addClass("d-none");
          Ember.$('#cityIsEmpty').addClass("d-none");
        }
      });
      Ember.$('#stateForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#stateForm").removeClass('has-error');
          Ember.$('#glyphState').addClass("d-none");
          Ember.$('#stateIsEmpty').addClass("d-none");
        }
      });
      Ember.$('#countryForm select').change(function () {
        if (this.value !== '') {
          Ember.$("#countryForm").removeClass('has-error');
          Ember.$('#glyphCountry').addClass("d-none");
          Ember.$('#countryIsEmpty').addClass("d-none");
        }
      });
      Ember.$('#phoneForm input').blur(function () {
        if (!isNaN(this.value)) {
          Ember.$("#phoneForm").removeClass('has-error');
          Ember.$('#glyphPhone').addClass('d-none');
          Ember.$('#phoneIsEmpty').addClass('d-none');
          Ember.$('#phoneIsNaN').addClass('d-none');
        }
      });
      Ember.$('#emailForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#emailForm").removeClass('has-error');
          Ember.$('#glyphEmail').addClass('d-none');
          Ember.$('#emailIsEmpty').addClass('d-none');
          Ember.$('#emailRegexError').addClass('d-none');
        }
      });
      Ember.$('#passwordForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#passwordForm").removeClass('has-error');
          Ember.$('#glyphPassword').addClass("d-none");
          Ember.$('#passwordIsEmpty').addClass("d-none");
        }

        if (regexPass.test(this.value)) {
          Ember.$("#passwordForm").removeClass('has-error');
          Ember.$('#glyphPassword').addClass("d-none");
          Ember.$('#passwordValidation').addClass("d-none");
        } else {
          Ember.$("#passwordForm").addClass('has-error');
          Ember.$('#glyphPassword').removeClass("d-none");
          Ember.$('#passwordValidation').removeClass("d-none");
        }
      });
      Ember.$('#passwordRepeatForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#passwordRepeatForm").removeClass('has-error');
          Ember.$('#glyphPasswordRepeat').addClass('d-none');
          Ember.$('#passwordRepeatIsEmpty').addClass('d-none');
          Ember.$('#passwordIsNotSame').addClass('d-none');
        }
      });
      Ember.$('#questionForm select').change(function () {
        if (this.value !== '') {
          Ember.$("#questionForm").removeClass('has-error');
          Ember.$('#glyphQuestion').addClass('d-none');
          Ember.$('#questionIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#answerForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#answerForm").removeClass('has-error');
          Ember.$('#glyphAnswer').addClass('d-none');
          Ember.$('#answerIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#answerRepeatForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#answerRepeatForm").removeClass('has-error');
          Ember.$('#glyphAnswerRepeat').addClass('d-none');
          Ember.$('#answerRepeatIsEmpty').addClass('d-none');
          Ember.$('#answerIsNotSame').addClass('d-none');
        }
      });
      Ember.$('#groupForm select').change(function () {
        if (this.value !== '') {
          Ember.$("#groupForm").removeClass('has-error');
          Ember.$('#glyphGroup').addClass('d-none');
          Ember.$('#groupIsEmpty').addClass('d-none');
        }
      });

      if (flag === 0) {
        if (this.get('create')) {
          Ember.$.ajax({
            url: '/api/v1/users',
            type: 'POST',
            data: JSON.stringify({
              user: {
                password: model.get('password'),
                firstName: model.get('firstName'),
                lastName: model.get('lastName'),
                tagline: model.get('tagline'),
                email: model.get('email').toLowerCase(),
                website: model.get('website'),
                postalCode: model.get('postalCode'),
                address: model.get('address'),
                address1: model.get('address1'),
                city: model.get('city'),
                state: model.get('state'),
                country: model.get('country.content.id'),
                phone: model.get('phone'),
                answer: model.get('answer'),
                secretquestion: model.get('secretquestion.content.id'),
                authEnable: model.get('authEnable'),
                status: 'Active'
              }
            }),
            contentType: 'application/json'
          }).then(function (response) {
            self.store.findRecord('user', response.user.id).then(function (user) {
              var usergroup = self.store.createRecord('usergroup', {
                user: user,
                group: controller.get('groupCon')
              });
              usergroup.save().then(function (usergroup) {
                self.transitionTo('users-roles.users');
              });
            }); // _this.get('session').authenticate('authenticator:signup', response);
          }, function (xhr, status, error) {
            alert("Error: ".concat(xhr.responseText));
          });
        } else {
          model.save().then(function (user) {
            Ember.$.ajax({
              url: '/api/v1/users/reset/',
              type: 'POST',
              data: {
                id: model.id,
                siteAdmin: true,
                email: model.get('email').toLowerCase(),
                sendPassToEmail: true,
                password: model.get('password')
              },
              dataType: 'json',
              contentType: 'application/x-www-form-urlencoded'
            }).then(function (response) {
              Ember.$.ajax({
                url: '/api/v1/users/resetans',
                type: 'POST',
                headers: requestHeaders,
                data: {
                  id: model.id,
                  siteAdmin: true,
                  email: model.get('email').toLowerCase(),
                  sendAnswerToEmail: true,
                  newanswer: model.get('answer')
                },
                dataType: 'json',
                contentType: 'application/x-www-form-urlencoded'
              }).then(function (response) {
                Ember.$.ajax({
                  url: '/api/v1/users/changequestion',
                  type: 'POST',
                  headers: requestHeaders,
                  data: {
                    id: model.id,
                    secretquestion: model.get('secretquestion.content.id')
                  },
                  dataType: 'json',
                  contentType: 'application/x-www-form-urlencoded'
                }).then(function (response) {
                  self.transitionTo('users-roles.users');
                }, function (xhr, status, error) {});
              });
            }, function (xhr, status, error) {
              alert("Error: ".concat(xhr.responseText));
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});