define("client/models/formpayment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    postPaymentAPI: (0, _attr.default)('string'),
    returnURL: (0, _attr.default)('string'),
    successURL: (0, _attr.default)('string'),
    paymentgatewaydetail: (0, _relationships.belongsTo)('paymentgatewaydetail', {
      async: true
    }) // form: belongsTo('form', { async: true })

  });

  _exports.default = _default;
});