define("client/pods/components/w3o-users-roles/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/56gI+T",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[1,[27,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"id\",\"class\"],[\"text\",[23,[\"filter\"]],\" Search\",\"search\",\"form-control\"]]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"red\"],[9],[1,[21,\"noResult\"],false],[10],[0,\"\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/search-bar/template.hbs"
    }
  });

  _exports.default = _default;
});