define("client/pods/components/modal/modal-pop-up/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deletecart: function deletecart() {
        this.deletecart();
      },

      /*massdelete: function() {
      	this.massdelete();
      },*/
      chooseaddress: function chooseaddress(addresss) {
        this.chooseaddress(addresss);
      },
      createaddress: function createaddress(addresss) {
        this.createaddress(addresss);
      },
      gotocheckout: function gotocheckout() {
        this.gotocheckout();
      },
      deleteaddress: function deleteaddress() {
        this.deleteaddress();
      },
      removeWishlist: function removeWishlist() {
        this.removeWishlist();
      },
      EditAddress: function EditAddress(address) {
        this.EditAddress(address);
      },
      confirmTransaction: function confirmTransaction(transactionnum) {
        this.confirmTransaction(transactionnum);
      },
      cancelledTransaction: function cancelledTransaction(transactionnum) {
        this.cancelledTransaction(transactionnum);
      },
      updateTestimony: function updateTestimony(transaction) {
        this.updateTestimony(transaction);
      }
    }
  });

  _exports.default = _default;
});