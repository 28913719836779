define("client/pods/confirm-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7iVu2fRp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[11,\"style\",\"margin-top: 85px;\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-4\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-4 p-0\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"card mx-auto signup-card-padding\"],[11,\"style\",\"width: 100%;\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n                    \"],[7,\"p\"],[11,\"class\",\"card-title text-center\"],[11,\"style\",\"font-size: 24px;\"],[9],[0,\"Email Confirmation\"],[10],[0,\"\\n                \"],[10],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"card-body p-0\"],[9],[0,\"\\n                    \"],[7,\"h4\"],[9],[0,\"Your email address is confirmed! You can now log in by clicking below.\"],[10],[0,\"\\n                    \"],[7,\"p\"],[11,\"class\",\"col-md-12 text-center margin-bot-5\"],[9],[0,\"Go back to \"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"Log In\"]],\"parameters\":[]},null],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-4\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/confirm-email/template.hbs"
    }
  });

  _exports.default = _default;
});