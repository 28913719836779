define("client/helpers/mapbox-coords", ["exports", "ember-cli-mapbox/helpers/mapbox-coords"], function (_exports, _mapboxCoords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mapboxCoords.default;
    }
  });
  Object.defineProperty(_exports, "mapboxCoords", {
    enumerable: true,
    get: function get() {
      return _mapboxCoords.mapboxCoords;
    }
  });
});