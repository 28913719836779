define("client/router", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("login");
    this.route("register");
    this.route('reset-password');
    this.route('reset', {
      path: '/reset/:key'
    });
    this.route('confirm-email', {
      path: '/confirm-email/:key'
    });
    this.route('authenticate', {});
    this.route('cart');
    this.route('checkout');
    this.route('product', {
      path: '/product/:id'
    });
    this.route('category', {
      path: '/category/:category_id'
    }, function () {
      this.route('sub', {
        path: ':id'
      });
      this.route('subsub', {
        path: ':id/:subsub_id'
      });
    });
    this.route('search');
    this.route('profile', {
      path: '/profile/:id'
    });
    this.route('temp-trans', {});
    this.route('transaction', function () {
      this.route('checkout', {
        path: '/checkout/:id'
      });
      this.route('payment-result');
      this.route('thank-you', {
        path: '/thank-you/:id'
      });
      this.route('unauthorized', {
        path: '/unauthorized/:route/:dynamic_value'
      });
      this.route('session-expired');
    }); //Error handling for nonexistent routes

    this.route('start', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});