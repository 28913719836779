define("client/pods/components/w3o-users-roles/auth-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    	Inject Session
    */
    session: Ember.inject.service('session'),
    actions: {
      /*
      Check whether the user is registered to the app or not via ember-simple-auth
      */
      authenticate: function authenticate() {
        var self = this;
        var flag = 0;
        this.set('errorMessage', null);

        if (Ember.isEmpty(this.get('identification'))) {
          this.set('emailMessage', 'Email must be filled');
          flag++;
        } else {
          this.set('emailMessage', null);
        }

        if (Ember.isEmpty(this.get('password'))) {
          this.set('passwordMessage', 'Password must be filled');
          flag++;
        } else {
          this.set('passwordMessage', null);
        }

        if (Ember.isEmpty(this.get('authCode'))) {
          this.set('authMessage', 'Authentication Code must be filled');
          flag++;
        } else {
          this.set('authMessage', null);
        }

        if (flag === 0) {
          var _this$getProperties = this.getProperties('identification', 'password', 'authCode'),
              identification = _this$getProperties.identification,
              password = _this$getProperties.password,
              authCode = _this$getProperties.authCode;

          this.get('session').authenticate('authenticator:authy', identification.toLowerCase(), password, authCode).then(function (result) {
            try {
              self.go();
            } catch (error) {
              console.log(error);
            }
          }, function (reason) {
            self.set('errorMessage', reason.error);
          });
        }
      }
    }
  });

  _exports.default = _default;
});