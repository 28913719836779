define("client/helpers/form-date-parse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formDateParse = formDateParse;
  _exports.default = void 0;

  function formDateParse(params) {
    switch (params[0]) {
      case 'DD-MM-YYYY HH:mm:ss':
        return 'd-m-Y H:i:S';

      case 'DD-MM-YYYY':
        return 'd-m-Y';

      case 'HH:mm:ss':
        return 'H:i:S';

      default:
        return params[0];
    }
  }

  var _default = Ember.Helper.helper(formDateParse);

  _exports.default = _default;
});