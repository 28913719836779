define("client/pods/components/upload/upload-profile-photo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _uploaderId: Ember.computed('elementId', function () {
      this.set('uploadid', 'S' + this.get('user') + this.get('date'));
      return this.get('elementId') + '-uploader';
    }),
    actions: {
      uploadButton: function uploadButton() {
        var self = this;

        if (this.get('file')) {
          if (!this.get('file').get('error')) {
            this.get('file').uploader.files.forEach(function (file) {
              self.get('file').uploader.files.removeObject(file);
            });
            this.get('file').queue.content.forEach(function (file) {
              self.get('file').queue.content.removeObject(file);
            });
            this.get('file').file.destroy();
          }
        }
      },
      queueFile: function queueFile(file) {
        var self = this;
        self.set('disabled', false);
        self.set('file', file);

        if (file.error) {
          self.set('error_maxfile', true);
        } else {
          self.set('error_maxfile', false);

          if (file.queue.content.length > 1) {
            if (file.queue.content.length > 1) {
              file.uploader.files.forEach(function (file) {
                file.uploader.files.removeObject(file);
              });
              file.queue.content.forEach(function (file) {
                file.queue.content.removeObject(file);
              });
              file.file.destroy();
            }
          }

          self.set('filename', file.get('name'));
          /*if (self.get('file')) {
                       $("#"+self.get('_uploaderId')).prop("style", "display: none;");
          	self.set('disabled', true);
          }*/

          file.read();
          self.queueFile(file);
        }
      }
    }
  });

  _exports.default = _default;
});