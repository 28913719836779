define("client/pods/components/w3o-users-roles/modal-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "17Exhp33",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"id\",\"modalDialog\"],[11,\"class\",\"modal\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n           \"],[14,1],[0,\"\\n        \"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/modal-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});