define("client/pods/components/general-component/datetime-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E/4dwNdB",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ember-flatpickr\",null,[[\"date\",\"onChange\",\"placeholder\",\"noCalendar\",\"disabled\",\"classNames\",\"dateFormat\"],[[27,\"readonly\",[[23,[\"date\"]]],null],[27,\"action\",[[22,0,[]],\"executeOnChange\"],null],[23,[\"placeholder\"]],[23,[\"noCalendar\"]],[23,[\"disabled\"]],\"datetime-picker\",\"d/m/Y\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/general-component/datetime-picker/template.hbs"
    }
  });

  _exports.default = _default;
});