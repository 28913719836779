define("client/pods/cart/route", ["exports", "axios", "client/mixins/cart/cart-list"], function (_exports, _axios, _cartList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_cartList.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {},
    model: function model() {},
    setupController: function setupController() {
      var self = this,
          controller = this.get('controller');
      controller.setProperties({
        domain: self.store.adapterFor('application').get('imagedomainHost'),
        deletedcart: 'deletedcart',
        massdeleted: 'massdeleted',
        disable: false,
        loadingPage: true,
        totalprice: 0,
        session: self.get('authObject.session')
      });
      console.log(self.get('authObject'));
      self.getCartlist();

      self._super();
    },
    actions: {
      prevList: function prevList(id) {
        this.prevList(id);
      },
      updateQty: function updateQty(id, qty) {
        this.updateQty(id, qty);
      },
      nextList: function nextList(id) {
        this.nextList(id);
      },
      checkcarts: function checkcarts(carts) {
        this.checkcarts(carts);
      },
      getdeletecart: function getdeletecart(id) {
        var controller = this.get('controller');
        controller.set('deletedidcart', id);
      },
      deletecart: function deletecart() {
        this.deletecart();
      },
      massdelete: function massdelete() {
        this.massdelete();
      },
      readypay: function readypay() {
        this.readypay();
      }
    }
  });

  _exports.default = _default;
});