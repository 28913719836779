define("client/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c0eTv8Vr",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"w3o-users-roles/header-main\"],[[\"noForm\"],[false]]],false],[0,\"\\n\"],[1,[27,\"component\",[\"w3o-users-roles/login-panel\"],null],false],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});