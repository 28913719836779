define("client/pods/reset/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _applicationRouteMixin, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _applicationRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      var self = this;

      this._super.apply(this, arguments);

      this.store.queryRecord('resetpass', {
        key: transition.params.reset.key
      }).then(function (param) {
        if (param) {
          return;
        } else {
          self.transitionTo('start');
        }
      });
    },
    model: function model(params) {
      return this.store.queryRecord('resetpass', {
        key: params.key
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('message', null);
      controller.set('success', false);
      this.store.queryRecord('user', {
        email: model.get('email')
      }).then(function (user) {
        controller.set('question', user.get('secretquestion.content.question'));
      });
    },
    actions: {
      reset: function reset() {
        var self = this;
        var flag = 0;
        var controller = this.get('controller');

        if (Ember.isEmpty(controller.get('password'))) {
          controller.set('message', 'Please input password!');
          /*setTimeout(function(){
                          controller.set('message', null);
                      }, 2000);*/

          flag++;
          return;
        } else {
          var regexPass = /.{6,}/;

          if (!regexPass.test(controller.get('password'))) {
            controller.set('message', 'Password must be at least 6 characters!');
            flag++;
            return;
          }
        }

        if (controller.get('password') !== controller.get('passwordRepeat')) {
          controller.set('message', 'Password is not same!');
          /*setTimeout(function(){
                          controller.set('message', null);
                      }, 2000);*/

          flag++;
          return;
        }

        if (!controller.get('model')) {
          controller.set('message', 'Key is invalid!');
          /*setTimeout(function(){
                          controller.set('message', null);
                      }, 2000);*/

          flag++;
          return;
        }

        if (Ember.isEmpty(controller.get('answer'))) {
          controller.set('message', 'Please input answer!');
          flag++;
          return;
        }

        if (flag === 0) {
          this.store.queryRecord('user', {
            email: controller.get('model').get('email')
          }).then(function (param) {
            Ember.$.ajax({
              url: '/api/v1/secretquestions/check/',
              type: 'POST',
              data: {
                id: param.id,
                answer: controller.get('answer')
              },
              dataType: 'json',
              contentType: 'application/x-www-form-urlencoded'
            }).then(function (response) {
              controller.set('message', null);
              var email = controller.get('model').get('email');
              var resetid = controller.get('model').get('id');
              self.store.queryRecord('user', {
                email: email
              }).then(function (user) {
                Ember.$.ajax({
                  url: '/api/v1/users/reset/',
                  type: 'POST',
                  data: {
                    id: user.id,
                    siteAdmin: true,
                    password: controller.get('password')
                  },
                  dataType: 'json',
                  contentType: 'application/x-www-form-urlencoded'
                }).then(function (response) {
                  Ember.$.ajax({
                    url: '/api/v1/resetpasses/' + resetid,
                    type: 'DELETE'
                  }).then(function (response) {
                    controller.set('success', true);
                    setTimeout(function () {
                      self.transitionTo('start');
                    }, 3000);
                  }, function (xhr, status, error) {// console.log(xhr);
                  });
                }, function (xhr, status, error) {// console.log(error);
                });
              });
            }, function (xhr, status, error) {
              controller.set('message', 'Answer is incorrect!');
              setTimeout(function () {
                controller.set('message', null);
              }, 2000);
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});