define("client/pods/reset-password/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _applicationRouteMixin, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  	Accessible only when user is not logged in
  */
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _applicationRouteMixin.default, {
    /*
    	Create model 'resetpass'
    */
    model: function model() {
      return this.store.createRecord('resetpass');
    },

    /*
    	Initialize values
    */
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('sendEmailSuccess', null);
      controller.set('message', null);
      controller.set('reCaptchaFlag', null);
      controller.set('recaptchaResult', null);
    },
    actions: {
      recaptchaWait: function recaptchaWait() {
        this.controller.set('reCaptchaFlag', false);
      },
      pipeRecaptchaResponse: function pipeRecaptchaResponse(success) {
        //pipe recaptcha result from component to route
        this.controller.set('reCaptchaFlag', true);
        this.controller.set('recaptchaResult', success);
      },
      resetPassword: function resetPassword() {
        var controller = this.get('controller');
        var email = this.get('currentModel').get('email');
        var flag = 0;
        var self = this;

        if (Ember.isEmpty(email)) {
          controller.set('message', 'Please input your email!');
          /*setTimeout(function(){
                          controller.set('message', null);
                      }, 2000);*/

          flag++;
          return;
        }

        if (Ember.isEmpty(controller.get('reCaptchaFlag')) && Ember.isEmpty(controller.get('recaptchaResult'))) {
          controller.set('message', 'Please fufill the reCaptcha');
          /*setTimeout(function(){
                          controller.set('message', null);
                      }, 2000);*/

          flag++;
          return;
        } else {
          if (controller.get('reCaptchaFlag')) {
            if (!controller.get('recaptchaResult')) {
              controller.set('message', 'Invalid Recaptcha Verification');
              /*setTimeout(function(){
                              controller.set('message', null);
                          }, 2000);*/

              flag++;
              return;
            }
          } else {
            controller.set('message', 'Please wait until reCaptcha is verified');
            /*setTimeout(function(){
                            controller.set('message', null);
                        }, 2000);*/

            flag++;
            return;
          }
        }

        if (flag === 0) {
          email = email.toLowerCase().trim();
          controller.setProperties({
            emailIsEmpty: false,
            message: null
          });
          this.store.queryRecord('user', {
            email: email
          }).then(function (param) {
            //check if there's email in db
            if (Ember.isEmpty(param)) {
              console.log("email not found");
              controller.set('message', 'Email not Found!');
              /*setTimeout(function(){
                              controller.set('message', null);
                          }, 2000);*/
            } else {
              var date = new Date();
              console.log("email found"); //check if email is already exist in reset password table

              self.store.queryRecord('resetpass', {
                email: email
              }).then(function (res) {
                if (res) {
                  Ember.$.ajax({
                    url: '/api/v1/resetpasses?id=' + res.id,
                    type: 'GET'
                  }).then(function (response) {
                    // console.log("email exist in reset table, updating key . . .");
                    //send user another reset password email
                    //update the record to reset password table for this email
                    Ember.$.ajax({
                      url: '/api/v1/resetpasses/' + res.id,
                      type: 'PATCH',
                      data: JSON.stringify({
                        resetpass: {
                          email: email,
                          key: Math.floor(Math.random() * 90000000) + 10000000
                        }
                      }),
                      contentType: 'application/json'
                    }).then(function (response) {
                      // console.log("key update success, email sent");
                      //then generate crypto link in server to reset email
                      controller.set('sendEmailSuccess', true);
                      Ember.$.ajax({
                        url: '/api/v1/resetlogs',
                        type: 'POST',
                        data: JSON.stringify({
                          resetlog: {
                            email: email,
                            type: 'request',
                            date: date
                          }
                        }),
                        contentType: 'application/json'
                      }).then(function (response) {}, function (xhr, status, error) {});
                    }, function (xhr, status, error) {
                      //send email error
                      controller.set('message', xhr.responseText);
                    }); //post new record to reset password table for this email
                  }, function (xhr, status, error) {
                    //send email error
                    controller.set('message', xhr.responseText);
                  });
                } else {
                  console.log("email not present on reset table, creating new one");
                  Ember.$.ajax({
                    url: '/api/v1/resetpasses',
                    type: 'POST',
                    data: JSON.stringify({
                      resetpass: {
                        key: Math.floor(Math.random() * 90000000) + 10000000,
                        email: email
                      }
                    }),
                    contentType: 'application/json'
                  }).then(function (response) {
                    //then generate crypto link in server to reset email
                    // console.log("key created, email sent");
                    controller.set('sendEmailSuccess', true);
                    Ember.$.ajax({
                      url: '/api/v1/resetlogs',
                      type: 'POST',
                      data: JSON.stringify({
                        resetlog: {
                          email: email,
                          type: 'request',
                          date: date
                        }
                      }),
                      contentType: 'application/json'
                    }).then(function (response) {}, function (xhr, status, error) {});
                  }, function (xhr, status, error) {
                    controller.set('message', xhr.responseText);
                  });
                }
              });
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});