define("client/pods/components/w3o-users-roles/header-nav/component", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "axios"], function (_exports, _authenticatedRouteMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_authenticatedRouteMixin.default, {
    container: '',
    newNotif: false,
    appLogo: '',
    authObject: Ember.inject.service('session'),
    imageLoaded: false,
    didInsertElement: function didInsertElement() {
      var el = document.querySelector('.customScrollbar');
      Ps.initialize(el);
    },
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      Ember.$.ajax({
        url: '/api/v1/applications/' + self.get('appSetting.appId'),
        type: 'GET',
        success: function success(result) {
          self.set('appLogo', result.applications.appLogo);
        },
        error: function error(xhr, status, _error) {}
      });
      this.set('menu', [{
        'title': 'User Management',
        'unique': 'user',
        'subunique': 'subuser',
        'glyph': 'fa-user',
        'theme': '#e64759',

        /*Ember.computed('border-color', function() {
        Note: You must implement #escapeCSS. 
        return Ember.String.htmlSafe("border-color: #f39c12");
        }),*/
        'color': Ember.computed('theme', function () {
          return Ember.String.htmlSafe('color: #e64759');
        }),
        'borderColor': Ember.computed('theme', function () {
          return Ember.String.htmlSafe('border-color: #e64759');
        }),
        'show': false,
        'submenu': [{
          'title': 'User List',
          'link': 'users-roles.users.index',
          'show': false
        }, {
          'title': 'Create New User',
          'link': 'users-roles.users.create',
          'show': false
        }, {
          'title': 'Assign Group to Users',
          'link': 'users-roles.groups',
          'show': false
        }]
      }, {
        'title': 'Role Management',
        'unique': 'role',
        'subunique': 'subrole',
        'glyph': 'fa-list',
        'theme': '#f39c12',

        /*Ember.computed('border-color', function() {
        Note: You must implement #escapeCSS. 
        return Ember.String.htmlSafe("border-color: #f39c12");
        }),*/
        'color': Ember.computed('theme', function () {
          return Ember.String.htmlSafe('color: #f39c12');
        }),
        'borderColor': Ember.computed('theme', function () {
          return Ember.String.htmlSafe('border-color: #f39c12');
        }),
        'show': false,
        'submenu': [{
          'title': 'Application List',
          'link': 'users-roles.role-manager.index',
          'show': false
        }, {
          'title': 'New Application',
          'link': 'users-roles.role-manager.create',
          'show': false
        }, {
          'title': 'Role List',
          'param': true,
          'type': 'role',
          'show': false
        }, {
          'title': 'Route Management',
          'param': true,
          'type': 'route',
          'show': false
        }, {
          'title': 'Grant/Revoke Access',
          'param': true,
          'type': 'access',
          'show': false
        }, {
          'title': 'Assign Role to Groups',
          'param': true,
          'type': 'assign',
          'show': false
        }, {
          'title': 'Manage App Access',
          'param': true,
          'type': 'app-access',
          'show': false
        }]
      }, {
        'title': 'Export / Import',
        'unique': 'exportimport',
        'subunique': 'subexportimport',
        'glyph': 'fa-folder-open',
        'theme': '#eecc99',
        'show': false,
        'link': 'users-roles.exportimport'
      }]);
    },
    // menu is array which contains navigation for template
    showCurrentApp: false,
    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;

      if (this.get('shownMenu')) {
        this.get('menu').forEach(function (header) {
          if (header.submenu) {
            header.submenu.forEach(function (submenu) {
              if (submenu.link && self.get('shownMenu').indexOf(submenu.link) > -1) {
                Ember.set(submenu, 'show', true);
              } else if (submenu.type === 'role' && self.get('shownMenu').indexOf('users-roles.role-manager.app.role') > -1) {
                Ember.set(submenu, 'show', true);
              } else if (submenu.type === 'access' && self.get('shownMenu').indexOf('users-roles.role-manager.app.access') > -1) {
                Ember.set(submenu, 'show', true);
              } else if (submenu.type === 'route' && self.get('shownMenu').indexOf('users-roles.role-manager.app.route') > -1) {
                Ember.set(submenu, 'show', true);
              } else if (submenu.type === 'assign' && self.get('shownMenu').indexOf('users-roles.role-manager.app.assign') > -1) {
                Ember.set(submenu, 'show', true);
              } else if (submenu.type === 'app-access' && self.get('shownMenu').indexOf('users-roles.role-manager.app.app-access') > -1) {
                Ember.set(submenu, 'show', true);
              }
            });
          }

          if (header.title === 'User Management' && (self.get('shownMenu').indexOf('users-roles.users.index') > -1 || self.get('shownMenu').indexOf('users-roles.users.create') > -1 || self.get('shownMenu').indexOf('users-roles.groups') > -1)) {
            Ember.set(header, 'show', true);
          }

          if (header.title === 'Role Management' && (self.get('shownMenu').indexOf('users-roles.role-manager.index') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.create') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.role') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.route') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.access') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.assign') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.app-access') > -1)) {
            Ember.set(header, 'show', true);
          }

          if (header.title === 'Export / Import' && self.get('shownMenu').indexOf('users-roles.exportimport') > -1) {
            Ember.set(header, 'show', true);
          }

          if (header.title === 'Tab Setting' && (self.get('shownMenu').indexOf('users-roles.tab-setting.index') > -1 || self.get('shownMenu').indexOf('users-roles.tab-setting.create') > -1)) {
            Ember.set(header, 'show', true);
          }

          if (self.get('shownMenu').indexOf('users-roles.role-manager.app.role') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.route') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.access') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.assign') > -1 || self.get('shownMenu').indexOf('users-roles.role-manager.app.app-access') > -1) {
            self.set('showCurrentApp', true);
          }
        });
      }

      if (!Ember.isEmpty(this.get('session'))) {
        if (!Ember.isEmpty(this.get('session.user.euser.content')) && this.get('imageLoaded') === false) {
          var companynum;
          this.set('imageLoaded', true);

          if (this.get('session.user.euser.companynum')) {
            companynum = this.get('session.user.euser.companynum');
          } else {
            companynum = this.get('session.user.euser.optmm_company');
          }

          companynum.then(function (company) {
            if (company) {
              var ajaxCall = function ajaxCall(url) {
                //Token authorization, enable this if your system using session managed by simple-auth
                // let accessToken = self.container.lookup('simple-auth-authorizer:oauth2-bearer').session.content.access_token;
                (0, _axios.default)({
                  method: 'POST',
                  url: url,
                  responseType: 'arraybuffer'
                }).catch(function (err) {
                  console.log('error');
                  console.log(err);
                }).then(function (e) {
                  if (e.status == 200) {
                    window.URL = window.URL || window.webkitURL;
                    var blob = new Blob([e.data], {
                      type: 'image/jpeg'
                    });
                    var reader = new window.FileReader();
                    reader.readAsDataURL(blob);

                    reader.onloadend = function () {
                      self.set('appLogo', reader.result);
                    };
                  }
                });
              };

              var url;

              if (uuid.test(company.get('companylogopath'))) {
                self.store.query('formuploadedfile', {
                  where: {
                    UUID: company.get('companylogopath')
                  }
                }).then(function (resFormuploadedfile) {
                  url = self.store.adapterFor('application').get('namespace') + "/uploads/downloadFormAttachment?fd=" + resFormuploadedfile.get('firstObject').get('filePath');
                  ajaxCall(url);
                });
              } else if (company.get('companylogopath')) {
                url = self.store.adapterFor('application').get('namespace') + "/uploads/downloadFormAttachment?fd=" + company.get('companylogopath');
                ajaxCall(url);
              }
            }
          });
        }
      }
    },
    // actions will sendAction to respective routes on template where this component is called
    actions: {
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      },
      showHideSidebar: function showHideSidebar() {
        // check sidebar shown/hidden
        var flag = Ember.$('.show-sidebar');

        if (flag.length === 0) {
          Ember.$('#headernavdesktop-accordion').addClass('show-sidebar');
          Ember.$('#headernavdesktop-accordion').removeClass('hide-sidebar');
          Ember.$('.sidebar-mask').show();
        } else {
          Ember.$('.show-sidebar').addClass('hide-sidebar');
          Ember.$('.show-sidebar').removeClass('show-sidebar');
          Ember.$('.sidebar-mask').hide();
        }
      },
      hideSidebarOnClick: function hideSidebarOnClick() {
        // hide sidebar on click
        Ember.$('.show-sidebar').addClass('hide-sidebar');
        Ember.$('.show-sidebar').removeClass('show-sidebar');
        Ember.$('.sidebar-mask').hide();
      }
    }
  });

  _exports.default = _default;
});