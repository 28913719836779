define("client/models/transactiondetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    price: _emberData.default.attr('number'),
    item_qty: _emberData.default.attr('number'),
    transaction_item_description: _emberData.default.attr('string'),
    total_price: _emberData.default.attr('number'),
    isdelete: _emberData.default.attr('boolean'),
    istestimony: _emberData.default.attr('boolean'),
    //relation	
    transactionnum: _emberData.default.belongsTo('transaction', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});