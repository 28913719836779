define("client/pods/components/w3o-users-roles/user-profile-photo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RcQWk411",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"style\",[21,\"loadingStyle\"]],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/images/ajax-loader.gif\"],[11,\"style\",\"width: 25px; height: 25px;\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"profilePicture\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"profPic2 row justify-content-center\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-8 col-lg-12 col-md-12 text-center\"],[9],[0,\"\\n        \"],[7,\"img\"],[12,\"id\",[28,[\"photo-\",[21,\"elementId\"]]]],[12,\"src\",[27,\"if\",[[23,[\"filePath\"]],[23,[\"filePath\"]],\"/images/blank-profile.png\"],null]],[11,\"alt\",\"profile image\"],[12,\"width\",[21,\"imgWidth\"]],[12,\"height\",[21,\"imgHeight\"]],[11,\"class\",\"img-fluid rounded-circle\"],[12,\"style\",[21,\"displayInline\"]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"profPic2 row justify-content-center\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-8 col-lg-12 col-md-12 text-center\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"src\",\"/images/blank-profile.png\"],[11,\"alt\",\"profile image\"],[12,\"width\",[21,\"imgWidth\"]],[12,\"height\",[21,\"imgHeight\"]],[11,\"class\",\"img-fluid rounded-circle\"],[12,\"style\",[21,\"displayInline\"]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/user-profile-photo/template.hbs"
    }
  });

  _exports.default = _default;
});