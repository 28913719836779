define("client/templates/components/loading-spin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1jugNIYm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",\"0 0 32 32\"],[12,\"width\",[21,\"loadingSvgSize\"]],[12,\"height\",[21,\"loadingSvgSize\"]],[12,\"fill\",[21,\"loadingSvgColor\"]],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"opacity\",\".25\"],[11,\"d\",\"M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4\"],[9],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"d\",\"M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z\"],[9],[0,\"\\n    \"],[7,\"animateTransform\"],[11,\"attributeName\",\"transform\"],[11,\"from\",\"0 16 16\"],[11,\"to\",\"360 16 16\"],[11,\"dur\",\"0.8s\"],[11,\"repeatCount\",\"indefinite\"],[11,\"type\",\"rotate\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/loading-spin.hbs"
    }
  });

  _exports.default = _default;
});