define("client/pods/index/route", ["exports", "axios", "client/mixins/category/category-list", "client/mixins/home/get-list"], function (_exports, _axios, _categoryList, _getList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_categoryList.default, _getList.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {},
    model: function model() {},
    setupController: function setupController() {
      var self = this,
          controller = this.get('controller');
      /*console.log(self.get('authObject'));
      console.log('isAuthenticated: '+ self.get('authObject.session.isAuthenticated'));*/

      controller.setProperties({
        domain: self.store.adapterFor('application').get('imagedomainHost')
      });
      self.getCategorylist();
      self.getProductlist();

      self._super();
    },
    actions: {
      getProductlist: function getProductlist(page) {
        this.getProductlist(page);
      }
    }
  });

  _exports.default = _default;
});