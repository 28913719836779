define("client/pods/authenticate/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _unauthenticatedRouteMixin, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _applicationRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (Ember.isEmpty(this.get('authObject.session.content.authenticated.isAuth'))) {
        this.transitionTo('start');
      }

      if (this.get('authObject.session.content.authenticated.isAuth')) {
        // this.transitionTo('users-roles.index');
        this.transitionTo('start');
      }
    },
    actions: {
      go: function go() {
        this.transitionTo('start');
      }
    }
  });

  _exports.default = _default;
});