define("client/instance-initializers/mapbox-access-code", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'mapbox-access-code',
    initialize: function initialize(instance) {
      if (!_environment.default.mapbox || !_environment.default.mapbox.accessToken) {
        console.error('Please specify your mapbox.accessToken in your config.');
        return;
      }

      L.mapbox.accessToken = _environment.default.mapbox.accessToken;
    }
  };
  _exports.default = _default;
});