define("client/pods/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BFg2IGjr",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"loading-pane\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"loading-message\"],[9],[0,\"\\n      Loading...\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"spinner\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/loading/template.hbs"
    }
  });

  _exports.default = _default;
});