define("client/templates/components/loading-bubbles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNLhG7sB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",\"0 0 32 32\"],[12,\"width\",[21,\"loadingSvgSize\"]],[12,\"height\",[21,\"loadingSvgSize\"]],[12,\"fill\",[21,\"loadingSvgColor\"]],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"transform\",\"translate(8 0)\"],[11,\"cx\",\"0\"],[11,\"cy\",\"16\"],[11,\"r\",\"0\"],[9],[0,\" \\n    \"],[7,\"animate\"],[11,\"attributeName\",\"r\"],[11,\"values\",\"0; 4; 0; 0\"],[11,\"dur\",\"1.2s\"],[11,\"repeatCount\",\"indefinite\"],[11,\"begin\",\"0\"],[11,\"keytimes\",\"0;0.2;0.7;1\"],[11,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8\"],[11,\"calcMode\",\"spline\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"circle\"],[11,\"transform\",\"translate(16 0)\"],[11,\"cx\",\"0\"],[11,\"cy\",\"16\"],[11,\"r\",\"0\"],[9],[0,\" \\n    \"],[7,\"animate\"],[11,\"attributeName\",\"r\"],[11,\"values\",\"0; 4; 0; 0\"],[11,\"dur\",\"1.2s\"],[11,\"repeatCount\",\"indefinite\"],[11,\"begin\",\"0.3\"],[11,\"keytimes\",\"0;0.2;0.7;1\"],[11,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8\"],[11,\"calcMode\",\"spline\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"circle\"],[11,\"transform\",\"translate(24 0)\"],[11,\"cx\",\"0\"],[11,\"cy\",\"16\"],[11,\"r\",\"0\"],[9],[0,\" \\n    \"],[7,\"animate\"],[11,\"attributeName\",\"r\"],[11,\"values\",\"0; 4; 0; 0\"],[11,\"dur\",\"1.2s\"],[11,\"repeatCount\",\"indefinite\"],[11,\"begin\",\"0.6\"],[11,\"keytimes\",\"0;0.2;0.7;1\"],[11,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8\"],[11,\"calcMode\",\"spline\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/loading-bubbles.hbs"
    }
  });

  _exports.default = _default;
});