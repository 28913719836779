define("client/pods/transaction/session-expired/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),
    actions: {
      goToStart: function goToStart() {
        this.get('authObject').invalidate().then(function () {
          var masterHost = this.store.adapterFor('application').get('masterHost');
          window.location.href = masterHost;
        });
      },
      willTransition: function willTransition(transition) {
        transition.abort();
        this.get('authObject').invalidate().then(function () {
          var masterHost = this.store.adapterFor('application').get('masterHost');
          window.location.href = masterHost;
        });
      }
    }
  });

  _exports.default = _default;
});