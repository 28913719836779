define("client/pods/components/w3o-users-roles/login-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
    	Inject Session
    */
    authObject: Ember.inject.service('session'),
    actions: {
      /*
      	Check whether the user is registered to the app or not via ember-simple-auth
      */
      authenticate: function authenticate() {
        var _this = this;

        var self = this;
        var flag = 0; // this.set('errorMessage', null);

        if (Ember.isEmpty(this.get('identification'))) {
          this.set('errorMessage', 'Email must be filled');
          flag++;
          return;
        }

        if (Ember.isEmpty(this.get('password'))) {
          this.set('errorMessage', 'Password must be filled');
          flag++;
          return;
        }

        if (flag === 0) {
          self.set('errorMessage', null);

          var _this$getProperties = this.getProperties('identification', 'password'),
              identification = _this$getProperties.identification,
              password = _this$getProperties.password;

          this.get('authObject').authenticate('authenticator:oauth2', identification.toLowerCase().trim(), password).then(function (result) {
            _this.setSession();
          }).catch(function (reason) {
            if (_this.get('authObject.session.isAuthenticated')) {
              _this.setSession();
            } else {
              _this.set('errorMessage', reason.error || reason);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});