define("client/models/transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    transactionid: _emberData.default.attr('string'),
    discount: _emberData.default.attr('number'),
    subtotal: _emberData.default.attr('number'),
    grandtotal: _emberData.default.attr('number'),
    shipping_fee: _emberData.default.attr('number'),
    paidtotal: _emberData.default.attr('number'),
    logistic_receipt_number: _emberData.default.attr('string'),
    transaction_date: _emberData.default.attr('date'),
    payment_date: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    send_email: _emberData.default.attr('boolean'),
    pdf_expired_on: _emberData.default.attr('date'),
    pdf_is_expired: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    receipt_no: _emberData.default.attr('number'),
    //relation	
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    useraddressnum: _emberData.default.belongsTo('useraddress', {
      async: true
    }),
    logisticnum: _emberData.default.belongsTo('logistic', {
      async: true
    }),
    formtransactionnum: _emberData.default.hasMany('formtransaction'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});