define("client/pods/confirm-email/route", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel(transition) {
      var self = this;
      this.store.queryRecord('user', {
        confirmationKey: transition.params['confirm-email'].key
      }).then(function (param) {
        if (param) {
          if (param.get('status') === 'Disabled') {
            (0, _axios.default)({
              method: "POST",
              data: {
                userId: param.id
              },
              url: self.store.adapterFor('application').get('namespace') + '/user/activateUser'
            }).then(function (response) {}).catch(function (error) {
              console.log('Error ' + error);
            });
          } else if (param.get('status') === 'Active') {
            return self.transitionTo('index');
          }
        } else {
          return self.transitionTo('index');
        }
      });
    },
    model: function model(params) {
      return this.store.queryRecord('user', {
        confirmationKey: params.key
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    }
  });

  _exports.default = _default;
});