define("client/pods/components/general-component/datetime-picker/component", ["exports", "client/pods/components/general-component/base-toplevel-component"], function (_exports, _baseToplevelComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseToplevelComponent.default.extend({
    primaryClassName: 'datetime-picker',
    date: null,
    enableTime: true,
    placeholder: null,
    noCalendar: false,
    time_24hr: true,
    actions: {
      executeOnChange: function executeOnChange(selectedDates, dateStr, instance) {
        if (this.onChange instanceof Function) {
          this.onChange(selectedDates, dateStr, instance);
        }
      }
    }
  });

  _exports.default = _default;
});