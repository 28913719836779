define("client/pods/authenticate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zOWxQnu7",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"w3o-users-roles/header-main\"],[[\"noForm\"],[true]]],false],[0,\"\\n\"],[1,[27,\"component\",[\"w3o-users-roles/auth-panel\"],[[\"go\"],[[27,\"route-action\",[\"go\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/authenticate/template.hbs"
    }
  });

  _exports.default = _default;
});