define("client/pods/transaction/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "client/mixins/w3office-forms/check-resource-access", "client/mixins/w3o-users-roles/invalidate-session"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin, _checkResourceAccess, _invalidateSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _checkResourceAccess.default, _invalidateSession.default, {
    authObject: Ember.inject.service('session'),
    actions: {
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      }
    }
  });

  _exports.default = _default;
});