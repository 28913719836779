define("client/pods/components/w3o-users-roles/login-bar/component", ["exports", "simple-auth/mixins/login-controller-mixin"], function (_exports, _loginControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_loginControllerMixin.default, {
    tagName: '',
    authenticator: 'simple-auth-authenticator:oauth2-password-grant'
  });

  _exports.default = _default;
});