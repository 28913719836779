define("client/models/promocode", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    promoname: attr('string'),
    promocode: attr('string'),
    discountpercentage: attr('number'),
    startdate: attr('date'),
    enddate: attr('date'),
    isdelete: attr('boolean', {
      defaultValue: false
    }),
    isactive: attr('boolean'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});