define("client/pods/checkout/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['product', 'qty', 'nt', 'vt', 'vr1', 'vr2', 'tr'],
    product: null,
    qty: null,
    nt: null,
    vt: null,
    vr1: null,
    vr2: null,
    tr: null
  });

  _exports.default = _default;
});