define("client/pods/category/subsub/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['value', 'min', 'max', 'page', 'filtercategory'],
    value: null,
    min: null,
    max: null,
    page: null,
    filtercategory: null
  });

  _exports.default = _default;
});