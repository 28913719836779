define("client/pods/components/category/category-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authObject: Ember.inject.service('session'),
    actions: {
      getFilterCategory: function getFilterCategory(param) {
        this.getFilterCategory(param);
      },
      getProduct: function getProduct(value, page) {
        this.getProduct(value, page);
      },
      getProductMin: function getProductMin(param) {
        this.getProductMin(param);
      },
      getProductMax: function getProductMax(param) {
        this.getProductMax(param);
      },
      getPage: function getPage(value) {
        this.getPage(value);
      },
      clearAll: function clearAll() {
        this.clearAll();
      },
      updateFin: function updateFin() {
        this.updateFin();
      },
      removeAll: function removeAll(value) {
        this.removeAll(value);
      }
    }
  });

  _exports.default = _default;
});