define("client/models/country", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    iso: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    nicename: _emberData.default.attr('string'),
    iso3: _emberData.default.attr('string'),
    numcode: _emberData.default.attr('string'),
    phonecode: _emberData.default.attr('string')
  });

  _exports.default = _default;
});