define("client/models/formtransaction", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    formID: (0, _attr.default)('string'),
    moduleID: (0, _attr.default)('string'),
    paymentDescription: (0, _attr.default)('string'),
    returnURL: (0, _attr.default)('string'),
    successURL: (0, _attr.default)('string'),
    postPaymentAPI: (0, _attr.default)('string'),
    currency: (0, _attr.default)('string'),
    paymentID: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number'),
    subscriptionFee: (0, _attr.default)('number'),
    totalAmount: (0, _attr.default)('number'),
    creditCardFirst6Digit: (0, _attr.default)('string'),
    creditCardLast4Digit: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    uniqueID: (0, _attr.default)('string'),
    isValid: (0, _attr.default)('boolean'),
    paymentgateway: (0, _relationships.belongsTo)('paymentgateway', {
      async: true
    }),
    paymentgatewaydetail: (0, _relationships.belongsTo)('paymentgatewaydetail', {
      async: true
    }),
    user: (0, _relationships.belongsTo)('user', {
      async: true
    })
  });

  _exports.default = _default;
});