define("client/pods/category/subsub/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4KoDeVis",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"component\",[\"category/category-page\"],[[\"categoryinfo\",\"isSub\",\"isSubSub\",\"subcategory\",\"subinfocategory\",\"subsubcategory\",\"grandpainfocategory\",\"subcategoryinfo\",\"subsubcategoryinfo\",\"products\",\"filterpop\",\"filternew\",\"filterbest\",\"filterprice\",\"filtercategory\",\"min\",\"max\",\"page\",\"pages\",\"totalpages\",\"firstpage\",\"lastpage\",\"prevpage\",\"nextpage\",\"disabledprev\",\"disablednext\",\"inputmin\",\"inputmax\",\"domain\",\"loadingPage\",\"getFilterCategory\",\"getProduct\",\"getProductMin\",\"getProductMax\",\"getPage\",\"removeAll\"],[[23,[\"categoryinfo\"]],[23,[\"isSub\"]],[23,[\"isSubSub\"]],[23,[\"subcategory\"]],[23,[\"subinfocategory\"]],[23,[\"subsubcategory\"]],[23,[\"grandpainfocategory\"]],[23,[\"subcategoryinfo\"]],[23,[\"subsubcategoryinfo\"]],[23,[\"products\"]],[23,[\"filterpop\"]],[23,[\"filternew\"]],[23,[\"filterbest\"]],[23,[\"filterprice\"]],[23,[\"filtercategory\"]],[23,[\"min\"]],[23,[\"max\"]],[23,[\"page\"]],[23,[\"pages\"]],[23,[\"totalpages\"]],[23,[\"firstpage\"]],[23,[\"lastpage\"]],[23,[\"prevpage\"]],[23,[\"nextpage\"]],[23,[\"disabledprev\"]],[23,[\"disablednext\"]],[23,[\"inputmin\"]],[23,[\"inputmax\"]],[23,[\"domain\"]],[23,[\"loadingPage\"]],[27,\"route-action\",[\"getFilterCategory\"],null],[27,\"route-action\",[\"getProduct\"],null],[27,\"route-action\",[\"getProductMin\"],null],[27,\"route-action\",[\"getProductMax\"],null],[27,\"route-action\",[\"getPage\"],null],[27,\"route-action\",[\"removeAll\"],null]]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/category/subsub/template.hbs"
    }
  });

  _exports.default = _default;
});