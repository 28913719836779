define("client/mixins/address/address-logic", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authObject: Ember.inject.service('session'),
    createaddress: function createaddress(address) {
      var self = this,
          controller = self.get('controller');
      var flag = 0;

      if (Ember.isEmpty(address.get('name'))) {
        controller.set('message', 'Please input your address name!');
        flag++;
      }

      if (Ember.isEmpty(address.get('address_1'))) {
        controller.set('message', 'Please input your address!');
        flag++;
      }

      if (Ember.isEmpty(address.get('address_2'))) {
        controller.set('message', 'Please input your address!');
        flag++;
      }

      if (Ember.isEmpty(address.get('city'))) {
        controller.set('message', 'Please input your city!');
        flag++;
      }

      if (Ember.isEmpty(address.get('state'))) {
        controller.set('message', 'Please input your state!');
        flag++;
      }

      if (Ember.isEmpty(address.get('country'))) {
        controller.set('message', 'Please input your country!');
        flag++;
      }

      if (Ember.isEmpty(address.get('postal_code'))) {
        controller.set('message', 'Please input your postal code!');
        flag++;
      }

      if (flag === 0) {
        (0, _axios.default)({
          method: "POST",
          data: {
            address_name: address.name,
            address_1: address.address_1,
            address_2: address.address_2,
            postal_code: address.postal_code,
            city: address.city,
            state: address.state,
            country: address.country,
            user: this.get('authObject.data.user').id,
            isdelete: false
          },
          url: self.store.adapterFor('application').get('namespace') + '/useraddress/createAddress'
        }).then(function (response) {
          console.log('Done');
          Ember.run.later(self, function () {
            location.reload();
          }, 1000);
        }).catch(function (error) {
          console.log('Error ' + error);
        });
      } else {
        console.log('flag');
        Ember.run.later(self, function () {
          controller.set('message', null);
          return;
        }, 2000);
      }
    },
    chooseaddress: function chooseaddress(address) {
      var self = this,
          controller = self.get('controller');
      controller.set('choosenaddress', address);
      (0, _axios.default)({
        method: "POST",
        data: {
          address: address
        },
        url: self.store.adapterFor('application').get('namespace') + '/useraddress/chooseAddress'
      }).then(function (response) {
        console.log('Done');
      }).catch(function (error) {
        console.log('Error ' + error);
      });
    },
    deleteaddress: function deleteaddress() {
      var self = this,
          controller = this.get('controller');
      (0, _axios.default)({
        method: "POST",
        data: {
          id: controller.get('deletedidaddress')
        },
        url: self.store.adapterFor('application').get('namespace') + '/useraddress/deleteAddress'
      }).then(function (response) {
        console.log('Deleted');
        Ember.run.later(self, function () {
          location.reload();
        }, 1000);
      }).catch(function (error) {
        console.log('Error ' + error);
      });
    },
    getEditAddress: function getEditAddress(value) {
      var controller = this.get('controller'),
          addresses = controller.get('address'),
          tempaddress = Ember.A(),
          Address = Ember.Object.extend({
        id: null,
        name: null,
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        isactive: null
      });
      addresses.forEach(function (add) {
        if (value === add.id) {
          var contentaddress = Address.create({
            id: add.id,
            name: add.name,
            address_1: add.address_1,
            address_2: add.address_2,
            city: add.city,
            state: add.state,
            country: add.country,
            postal_code: add.postal_code
          });
          tempaddress.pushObject(contentaddress);
          controller.set('editaddresspicked', tempaddress[0]);
        }
      });
    },
    EditAddress: function EditAddress(address) {
      var self = this,
          controller = self.get('controller');
      var flag = 0;

      if (Ember.isEmpty(address.get('name'))) {
        controller.set('message', 'Please input your address name!');
        flag++;
      }

      if (Ember.isEmpty(address.get('address_1'))) {
        controller.set('message', 'Please input your address!');
        flag++;
      }

      if (Ember.isEmpty(address.get('address_2'))) {
        controller.set('message', 'Please input your address!');
        flag++;
      }

      if (Ember.isEmpty(address.get('city'))) {
        controller.set('message', 'Please input your city!');
        flag++;
      }

      if (Ember.isEmpty(address.get('state'))) {
        controller.set('message', 'Please input your state!');
        flag++;
      }

      if (Ember.isEmpty(address.get('country'))) {
        controller.set('message', 'Please input your country!');
        flag++;
      }

      if (Ember.isEmpty(address.get('postal_code'))) {
        controller.set('message', 'Please input your postal code!');
        flag++;
      }

      if (flag === 0) {
        (0, _axios.default)({
          method: "POST",
          data: {
            id: address.id,
            address_name: address.name,
            address_1: address.address_1,
            address_2: address.address_2,
            postal_code: address.postal_code,
            city: address.city,
            state: address.state,
            country: address.country,
            isdelete: false
          },
          url: self.store.adapterFor('application').get('namespace') + '/useraddress/editAddress'
        }).then(function (response) {
          console.log('Done');
          Ember.run.later(self, function () {
            location.reload();
          }, 1000);
        }).catch(function (error) {
          console.log('Error ' + error);
        });
      } else {
        Ember.run.later(self, function () {
          controller.set('message', null);
          return;
        }, 2000);
      }
    }
  });

  _exports.default = _default;
});