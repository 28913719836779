define("client/mixins/w3o-users-roles/check-session-activity", ["exports", "client/mixins/w3o-users-roles/invalidate-session"], function (_exports, _invalidateSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_invalidateSession.default, {
    authObject: Ember.inject.service('session'),
    checkSessionActivity: function checkSessionActivity() {
      var t;
      var self = this;
      var duration = this.store.adapterFor('application').get('inactivityDuration') * 60 * 1000; // minutes * seconds * miliseconds
      // let prevKey = "";
      // let validNavigation = false;

      window.onload = function () {
        var now = Date.now();

        if (now - self.get('authObject').get('data.lastActivity') > duration) {
          return checkSession();
        }

        return resetTimer;
      };

      window.onmousemove = resetTimer;
      window.onmousedown = resetTimer; // catches touchscreen presses as well      

      window.ontouchstart = resetTimer; // catches touchscreen swipes as well 

      window.onclick = resetTimer; // catches touchpad clicks as well

      window.onkeypress = resetTimer;
      window.onfocus = resetTimer;
      window.addEventListener('scroll', resetTimer, true); // improved; see comments

      window.onbeforeunload = function () {
        if (self.get('authObject.data.user')) {
          self.get('authObject').set('data.lastActivity', Date.now());
        }
      }; // Attach the event click for all links in the page


      Ember.$(document).on("click", "a", function () {
        // validNavigation = true;
        // window.onbeforeunload = null;
        setLastActivity();
      }); // Attach the event submit for all forms in the page

      Ember.$(document).on("submit", "form", function () {
        // validNavigation = true;
        // window.onbeforeunload = null;
        setLastActivity();
      }); // Attach the event click for all inputs in the page

      Ember.$(document).bind("click", "input[type=submit]", function () {
        // validNavigation = true;
        // window.onbeforeunload = null;
        setLastActivity();
      });
      Ember.$(document).bind("click", "button[type=submit]", function () {
        // validNavigation = true;
        // window.onbeforeunload = null;
        setLastActivity();
      }); // window.onbeforeunload = function() {
      // 	if (!validNavigation){
      // 		// return checkSession();
      // 		if (self.get('authObject.data.user')) {
      // 			self.get('authObject').set('data.lastActivity', Date.now());
      // 		}
      // 	}
      // }
      // $(window).on('mouseover', function () {
      // 	window.onbeforeunload = null;
      // });
      // $(window).on('mouseout', function () {
      // 	window.onbeforeunload = checkSession;
      // });

      /*$(document).keydown(function (e) {
      	if (e.key.toUpperCase() === "W" && prevKey === "CONTROL") {
      		window.onbeforeunload = checkSession;
      	}
      	else if (e.key.toUpperCase() === "F4" && (prevKey === "ALT" || prevKey === "CONTROL")) {
      		window.onbeforeunload = checkSession;
      	}
      	else if (e.key.toUpperCase() === 'CONTROL') {
      		window.onbeforeunload = checkSession;
      	}
      	else if (e.key === "F5") {
      		window.onbeforeunload = checkSession;
      	}
      	else if (e.key.toUpperCase() === "R" && prevKey === "CONTROL") {
      		window.onbeforeunload = checkSession;
      	}
      	else {
      		window.onbeforeunload = null;
      	}
      	prevKey = e.key.toUpperCase();
      });*/

      function setLastActivity() {
        if (self.get('authObject.data.user')) {
          self.get('authObject').set('data.lastActivity', Date.now());
        }
      }

      function checkSession() {
        // your function for too long inactivity goes here
        if (self.get('authObject.data.user')) {
          return self.invalidateSession();
        }
      }

      function resetTimer() {
        clearTimeout(t);
        t = setTimeout(checkSession, duration); // time is in milliseconds

        setLastActivity();
      }
    }
  });

  _exports.default = _default;
});