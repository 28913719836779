define("client/models/customer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    customerid: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    dob: _emberData.default.attr('date'),
    fax: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    profilepic: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    ic_number: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    nationality: _emberData.default.belongsTo('country', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});