define("client/templates/components/loading-cylon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nUAD9BFK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",\"0 0 32 32\"],[12,\"width\",[21,\"loadingSvgSize\"]],[12,\"height\",[21,\"loadingSvgSize\"]],[12,\"fill\",[21,\"loadingSvgColor\"]],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"transform\",\"translate(0 0)\"],[11,\"d\",\"M0 12 V20 H4 V12z\"],[9],[0,\"\\n    \"],[7,\"animateTransform\"],[11,\"attributeName\",\"transform\"],[11,\"values\",\"0 0; 28 0; 0 0; 0 0\"],[11,\"dur\",\"1.5s\"],[11,\"begin\",\"0\"],[11,\"repeatCount\",\"indefinite\"],[11,\"keytimes\",\"0;0.3;0.6;1\"],[11,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8\"],[11,\"calcMode\",\"spline\"],[11,\"type\",\"translate\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"opacity\",\"0.5\"],[11,\"transform\",\"translate(0 0)\"],[11,\"d\",\"M0 12 V20 H4 V12z\"],[9],[0,\"\\n    \"],[7,\"animateTransform\"],[11,\"attributeName\",\"transform\"],[11,\"values\",\"0 0; 28 0; 0 0; 0 0\"],[11,\"dur\",\"1.5s\"],[11,\"begin\",\"0.1s\"],[11,\"repeatCount\",\"indefinite\"],[11,\"keytimes\",\"0;0.3;0.6;1\"],[11,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8\"],[11,\"calcMode\",\"spline\"],[11,\"type\",\"translate\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"path\"],[11,\"opacity\",\"0.25\"],[11,\"transform\",\"translate(0 0)\"],[11,\"d\",\"M0 12 V20 H4 V12z\"],[9],[0,\"\\n    \"],[7,\"animateTransform\"],[11,\"attributeName\",\"transform\"],[11,\"values\",\"0 0; 28 0; 0 0; 0 0\"],[11,\"dur\",\"1.5s\"],[11,\"begin\",\"0.2s\"],[11,\"repeatCount\",\"indefinite\"],[11,\"keytimes\",\"0;0.3;0.6;1\"],[11,\"keySplines\",\"0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8\"],[11,\"calcMode\",\"spline\"],[11,\"type\",\"translate\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/templates/components/loading-cylon.hbs"
    }
  });

  _exports.default = _default;
});