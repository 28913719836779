define("client/pods/transaction/unauthorized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fIVf/KhM",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"container col-md-offset-2 col-md-10\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"loading-pane\"],[9],[0,\"\\n\\t    \"],[7,\"div\"],[11,\"class\",\"loading-message panel panel-default\"],[9],[0,\"\\n\\t     \\t\"],[7,\"h3\"],[9],[0,\"You are not authorised to access this \\\"\"],[1,[21,\"pageName\"],false],[0,\"\\\" page.\"],[7,\"br\"],[9],[10],[0,\"\\n\\t     \\tPlease contact your application administrator to resolve this issue.\"],[10],[0,\"\\n\\t    \"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/transaction/unauthorized/template.hbs"
    }
  });

  _exports.default = _default;
});