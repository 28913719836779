define("client/pods/components/upload/upload-profile-photo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3lmVSruY",
    "block": "{\"symbols\":[\"queue\"],\"statements\":[[4,\"pl-uploader\",null,[[\"extensions\",\"for\",\"max-file-size\",\"disabled\",\"multiple\",\"extensions\",\"name\",\"onfileadd\"],[\"jpg jpeg png\",[23,[\"_uploaderId\"]],10000000,true,false,\"jpg jpeg png\",[23,[\"uploadid\"]],\"queueFile\"]],{\"statements\":[[0,\"\\t\"],[7,\"button\"],[12,\"id\",[21,\"_uploaderId\"]],[11,\"class\",\"form-control btn btn-light\"],[12,\"disabled\",[21,\"disabled\"]],[11,\"style\",\"display:block; font-size: 12px; margin-bottom: 1%;\"],[3,\"action\",[[22,0,[]],\"uploadButton\"]],[9],[0,\"Upload\"],[10],[0,\"  \\n     \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"loadingbar\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"length\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"progress\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"progress-bar\"],[11,\"role\",\"progressbar\"],[12,\"aria-valuenow\",[22,1,[\"progress\"]]],[11,\"aria-valuemin\",\"0\"],[11,\"aria-valuemax\",\"100\"],[12,\"style\",[27,\"sanitize-css-unit\",[[22,1,[\"progress\"]],\"width: \",\"%\"],null]],[9],[0,\"\\n                    \"],[1,[22,1,[\"progress\"]],false],[0,\"%\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[4,\"if\",[[23,[\"error_maxfile\"]]],null,{\"statements\":[[0,\"    \"],[7,\"font\"],[11,\"color\",\"red\"],[9],[0,\"File is over 10 MB\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/upload/upload-profile-photo/template.hbs"
    }
  });

  _exports.default = _default;
});