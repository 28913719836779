define("client/pods/components/category/category-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ukHqGceK",
    "block": "{\"symbols\":[\"categories\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row mx-0\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"category\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-center mb-1\"],[11,\"style\",\"width: 30%; margin-right: 3%;\"],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[28,[\"/category/\",[22,1,[\"id\"]]]]],[11,\"style\",\"color: black;\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn roundbutton peachybackground\"],[11,\"style\",\"width: 55px; height: 55px; border-style: none;\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-2x fa-map\"],[11,\"style\",\"width: 30px;height: 15px;\"],[9],[10],[10],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"category-text mt-1\"],[11,\"style\",\"font-size: 12px;\"],[9],[1,[22,1,[\"category_name\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/category/category-list/template.hbs"
    }
  });

  _exports.default = _default;
});