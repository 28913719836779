define("client/pods/components/w3o-users-roles/user-profile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: false,
    currentUser: true,
    profilepic: '',
    dataURI: '',
    accessHeader: '',
    isUploading: false,
    oldPasswordIsEmpty: null,
    newPasswordIsEmpty: null,
    passwordRepeatIsEmpty: null,
    newPasswordIsNotSameWithRepeat: null,
    newPasswordSameAsOld: null,
    passwordError: null,
    oldPasswordError: null,
    siteAdmin: false,
    //change this to variable that determine the user role
    authObject: Ember.inject.service('session'),
    didInsertElement: function didInsertElement() {
      var user = this.get('authObject.data.user.id');
      /*var accessToken = this.container.lookup('simple-auth-authorizer:oauth2-bearer').session.content.access_token;
      var requestHeaders = { Authorization: 'bearer ' + accessToken };*/

      if (user !== this.get('user.id')) {
        this.set('currentUser', false);
      } // this.set('accessHeader', requestHeaders);
      // this.session.restore('user');


      this.checkEmail();
      this.store.findAll('usergroup');
    },
    checkEmail: Ember.observer('user.email', 'user.firstName', 'user.lastName', 'user.country.content', 'user.phone', 'user.postalCode', 'user.address', 'user.address1', 'user.city', 'user.state', function () {
      Ember.$('#emailForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#emailForm").removeClass('has-error');
          Ember.$('#glyphEmail').addClass('d-none');
          Ember.$('#emailError').addClass('d-none');
          var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

          if (regexEmail.test(this.value.toLowerCase())) {
            Ember.$("#emailForm").removeClass('has-error');
            Ember.$('#glyphEmail').addClass('d-none');
            Ember.$('#emailError2').addClass('d-none');
          } else {
            Ember.$("#emailForm").addClass('has-error');
            Ember.$('#glyphEmail').removeClass('d-none');
            Ember.$('#emailError2').removeClass('d-none');
          }
        }
      });
      Ember.$('#firstNameForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#firstNameForm").removeClass('has-error');
          Ember.$('#glyphFirstName').addClass('d-none');
          Ember.$('#firstNameIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#lastNameForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#lastNameForm").removeClass('has-error');
          Ember.$('#glyphLastName').addClass('d-none');
          Ember.$('#lastNameIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#phoneForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#phoneForm").removeClass('has-error');
          Ember.$('#glyphPhone').addClass('d-none');
          Ember.$('#phoneIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#postalCodeForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#postalCodeForm").removeClass('has-error');
          Ember.$('#glyphPostalCode').addClass('d-none');
          Ember.$('#postalCodeIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#addressForm input').ready(function () {
        if (this.value !== '') {
          Ember.$("#addressForm").removeClass('has-error');
          Ember.$('#glyphAddress').addClass('d-none');
          Ember.$('#addressIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#address1Form input').ready(function () {
        if (this.value !== '') {
          Ember.$("#address1Form").removeClass('has-error');
          Ember.$('#glyphAddress1').addClass('d-none');
          Ember.$('#address1IsEmpty').addClass('d-none');
        }
      });
      Ember.$('#cityForm input').ready(function () {
        if (this.value !== '') {
          Ember.$("#cityForm").removeClass('has-error');
          Ember.$('#glyphCity').addClass('d-none');
          Ember.$('#cityIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#stateForm input').ready(function () {
        if (this.value !== '') {
          Ember.$("#stateForm").removeClass('has-error');
          Ember.$('#glyphState').addClass('d-none');
          Ember.$('#stateIsEmpty').addClass('d-none');
        }
      });
      Ember.$('#countryForm input').ready(function () {
        if (this.value !== '') {
          Ember.$("#countryForm").removeClass('has-error');
          Ember.$('#glyphCountry').addClass('d-none');
          Ember.$('#countryIsEmpty').addClass('d-none');
        }
      });
    }),
    getInfoDebouncer: function getInfoDebouncer(param) {
      this.getInfo(param);
    },
    actions: {
      //change isEditing to true
      editProfile: function editProfile() {
        this.set('isEditing', true);
      },
      setStatus: function setStatus() {
        var user = this.get('user');
        user.save();
      },
      changeStatus: function changeStatus() {
        var user = this.get('model');
        this.changeStatus(user);
      },
      getInfo: function getInfo(param) {
        Ember.run.debounce(this, this.getInfoDebouncer, param, 800);
      },
      saveEdit: function saveEdit() {
        var user = this.get('model');
        var flag = 0;
        var regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        if (Ember.isEmpty(user.get('firstName'))) {
          Ember.$("#firstNameForm").addClass("has-error");
          Ember.$("#firstNameIsEmpty").removeClass("d-none");
          Ember.$("#glyphFirstName").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('lastName'))) {
          Ember.$("#lastNameForm").addClass("has-error");
          Ember.$("#lastNameIsEmpty").removeClass("d-none");
          Ember.$("#glyphLastName").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('postalCode'))) {
          Ember.$("#postalCodeForm").addClass("has-error");
          Ember.$("#postalCodeIsEmpty").removeClass("d-none");
          Ember.$("#glyphPostalCode").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('address'))) {
          Ember.$("#addressForm").addClass("has-error");
          Ember.$("#addressIsEmpty").removeClass("d-none");
          Ember.$("#glyphAddress").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('address1'))) {
          Ember.$("#address1Form").addClass("has-error");
          Ember.$("#address1IsEmpty").removeClass("d-none");
          Ember.$("#glyphAddress1").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('city'))) {
          Ember.$("#cityForm").addClass("has-error");
          Ember.$("#cityIsEmpty").removeClass("d-none");
          Ember.$("#glyphCity").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('state'))) {
          Ember.$("#stateForm").addClass("has-error");
          Ember.$("#stateIsEmpty").removeClass("d-none");
          Ember.$("#glyphState").removeClass("d-none");
          flag++;
        }

        if (Ember.isEmpty(user.get('country.content'))) {
          Ember.$("#countryCodeForm").addClass("has-error");
          Ember.$("#countryCodeIsEmpty").removeClass("d-none");
          Ember.$("#glyphCountryCode").removeClass("d-none");
          flag++;
        }
        /*else if (isNaN(user.get('countryCode'))){
            $( "#countryCodeForm" ).addClass( "has-error" );
            $( "#countryCodeIsNaN" ).removeClass( "d-none" );
            $( "#glyphCountryCode" ).removeClass( "d-none" );
            flag++;
        }*/


        if (Ember.isEmpty(user.get('phone'))) {
          Ember.$("#phoneForm").addClass("has-error");
          Ember.$("#phoneIsEmpty").removeClass("d-none");
          Ember.$("#glyphPhone").removeClass("d-none");
          flag++;
        }

        if (!/\S/.test(user.get('email')) || !user.get('email')) {
          Ember.$("#emailForm").addClass("has-error");
          Ember.$('#glyphEmail').removeClass('d-none');
          Ember.$('#emailError').removeClass('d-none');
          Ember.$('#emailError2').addClass('d-none');
          flag++;
          user.set('email', '');
        } else if (!regexEmail.test(user.get('email')) || !user.get('email')) {
          Ember.$("#emailForm").addClass("has-error");
          Ember.$('#glyphEmail').removeClass('d-none');
          Ember.$('#emailError2').removeClass('d-none');
          Ember.$('#emailError').addClass('d-none');
          flag++;
          user.set('email', '');
        }

        if (flag === 0) {
          this.saveEdit(user);
        }
      },
      //set isEditing to false
      cancelEdit: function cancelEdit() {
        this.set('isEditing', false);
        this.set('isUploading', false);
        this.set('profilepic', '');
        this.set('dataURI', '');
      },
      queuePhoto: function queuePhoto(file) {
        var self = this;
        this.set('isUploading', true);
        this.set('profilepic', file);
        file.read().then(function setPreviewUrl(dataURI) {
          self.set('dataURI', dataURI);
        });
      },
      changePassword: function changePassword() {
        var model = this.get('model');
        var flag = 0;
        var regexPass = /.{6,}/;

        if (Ember.isEmpty(model.get('oldpassword')) && !this.get('canReset')) {
          Ember.$("#oldForm").addClass("has-error");
          Ember.$('#glyphOld').removeClass('d-none');
          Ember.$('#oldError').removeClass('d-none');
          Ember.$('#oldError2').addClass('d-none');
          flag++; // model.set('oldpassword', null);
        } else {
          Ember.$("#oldForm").removeClass("has-error");
          Ember.$('#glyphOld').addClass('d-none');
          Ember.$('#oldError').addClass('d-none');
          Ember.$('#oldError2').addClass('d-none');
        }

        if (Ember.isEmpty(model.get('newpassword'))) {
          Ember.$("#newForm").addClass("has-error");
          Ember.$('#glyphNew').removeClass('d-none');
          Ember.$('#newError').removeClass('d-none');
          Ember.$('#newError2').addClass('d-none');
          Ember.$('#newError3').addClass('d-none');
          flag++; // model.set('newpassword', null);
        } else if (!regexPass.test(model.get('newpassword'))) {
          Ember.$("#newForm").addClass("has-error");
          Ember.$('#glyphNew').removeClass('d-none');
          Ember.$('#newError3').removeClass('d-none');
          Ember.$('#newError').addClass('d-none');
          Ember.$('#newError2').addClass('d-none');
          flag++;
        } else if (model.get('newpassword') === model.get('oldpassword') && !this.get('canReset')) {
          Ember.$("#newForm").addClass("has-error");
          Ember.$('#glyphNew').removeClass('d-none');
          Ember.$('#newError2').removeClass('d-none');
          Ember.$('#newError').addClass('d-none');
          Ember.$('#newError3').addClass('d-none');
          flag++; // model.set('newpassword', null);
        } else {
          Ember.$("#newForm").removeClass("has-error");
          Ember.$('#glyphNew').addClass('d-none');
          Ember.$('#newError').addClass('d-none');
          Ember.$('#newError2').addClass('d-none');
          Ember.$('#newError3').addClass('d-none');
        }

        if (Ember.isEmpty(model.get('passwordRepeat'))) {
          Ember.$("#repeatForm").addClass("has-error");
          Ember.$('#glyphRepeat').removeClass('d-none');
          Ember.$('#repeatError').removeClass('d-none');
          Ember.$('#repeatError2').addClass('d-none');
          flag++; // model.set('passwordRepeat', null);
        } else if (model.newpassword !== model.passwordRepeat) {
          Ember.$("#repeatForm").addClass("has-error");
          Ember.$('#glyphRepeat').removeClass('d-none');
          Ember.$('#repeatError2').removeClass('d-none');
          Ember.$('#repeatError').addClass('d-none');
          flag++; // model.set('passwordRepeat', null);
        } else {
          Ember.$("#repeatForm").removeClass("has-error");
          Ember.$('#glyphRepeat').addClass('d-none');
          Ember.$('#repeatError').addClass('d-none');
          Ember.$('#repeatError2').addClass('d-none');
        }

        if (flag === 0) {
          this.changePassword(model);
        }
      },
      changeQuestion: function changeQuestion() {
        console.log('changeQuestion');
        var model = this.get('model');
        var flag = 0; //validation

        console.log(Ember.isEmpty(model.get('currentPassword')));
        console.log(!this.get('canReset'));

        if (Ember.isEmpty(model.currentPassword) && !this.get('canReset')) {
          Ember.$("#currentPasswordForm").addClass("has-error");
          Ember.$('#glyphcurrentPassword').removeClass('d-none');
          Ember.$('#currentPasswordIsEmpty').removeClass('d-none');
          Ember.$('#currentPasswordIsNotSame').addClass('d-none');
          flag++;
        }

        if (Ember.isEmpty(model.get('newAnswer'))) {
          Ember.$("#newanswerForm").addClass("has-error");
          Ember.$('#glyphNewanswer').removeClass('d-none');
          Ember.$('#newanswerError').removeClass('d-none');
          Ember.$('#newanswerError2').addClass('d-none');
          flag++;
          model.set('newAnswer', null);
        }

        if (Ember.isEmpty(model.get('answerRepeat'))) {
          Ember.$("#repeatanswerForm").addClass("has-error");
          Ember.$('#glyphRepeatanswer').removeClass('d-none');
          Ember.$('#repeatanswerError').removeClass('d-none');
          Ember.$('#repeatanswerError2').addClass('d-none');
          flag++;
          model.set('answerRepeat', null);
        } else if (model.get('newAnswer') !== model.get('answerRepeat')) {
          Ember.$("#repeatanswerForm").addClass("has-error");
          Ember.$('#glyphRepeatanswer').removeClass('d-none');
          Ember.$('#repeatanswerError2').removeClass('d-none');
          Ember.$('#repeatanswerError').addClass('d-none');
          flag++;
          model.set('answerRepeat', null);
        }

        if (Ember.isEmpty(model.get('newQuestion'))) {
          Ember.$("#newquestionForm").addClass("has-error");
          Ember.$('#glyphNewquestion').removeClass('d-none');
          Ember.$('#newquestionError').removeClass('d-none');
          Ember.$('#newquestionError2').addClass('d-none');
          flag++;
          model.set('newQuestion', null);
        } else {
          Ember.$("#newquestionForm").removeClass("has-error");
          Ember.$('#glyphNewquestion').addClass('d-none');
          Ember.$('#newquestionError').addClass('d-none');
          Ember.$('#newquestionError2').addClass('d-none');
        }

        if (flag === 0) {
          this.changeQuestion(model);
        }
      }
    }
  });

  _exports.default = _default;
});