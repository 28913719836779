define("client/mixins/search/search-filter-logic", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getFilterCategory: function getFilterCategory(value) {
      var controller = this.get('controller'),
          filterpop = null,
          filternew = null,
          filterbest = null,
          filterprice = null;
      controller.set('filtercategory', value);

      if (value === 'popular') {
        controller.set('filterpop', 'background-color: #E8A892 !important; color:white !important;');
        controller.set('filternew', null);
        controller.set('filterbest', null);
        controller.set('filterprice', null);
      } else if (value === 'new') {
        controller.set('filterpop', null);
        controller.set('filternew', 'background-color: #E8A892 !important; color:white !important;');
        controller.set('filterbest', null);
        controller.set('filterprice', null);
      } else if (value === 'bestselling') {
        controller.set('filterpop', null);
        controller.set('filternew', null);
        controller.set('filterbest', 'background-color: #E8A892 !important; color:white !important;');
        controller.set('filterprice', null);
      } else if (value === 'low' || 'high') {
        controller.set('filterpop', null);
        controller.set('filternew', null);
        controller.set('filterbest', null);
        controller.set('filterprice', 'background-color: #E8A892 !important; color:white !important;');
      }
    },
    getPage: function getPage(value) {
      var controller = this.get('controller');
      controller.set('page', value);
    },
    getProductMin: function getProductMin(value) {
      var controller = this.get('controller');

      if (value) {
        controller.set('min', value);
      } else {
        controller.set('min', null);
      }
    },
    getProductMax: function getProductMax(value) {
      var controller = this.get('controller');

      if (value) {
        controller.set('max', value);
      } else {
        controller.set('max', null);
      }
    }
  });

  _exports.default = _default;
});