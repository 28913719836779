define("client/pods/components/header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authObject: Ember.inject.service('session'),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var self = this;
        var flag = 0; // this.set('errorMessage', null);

        if (Ember.isEmpty(this.get('identification'))) {
          this.set('errorMessage', 'Email must be filled');
          flag++;
          return;
        }

        if (Ember.isEmpty(this.get('password'))) {
          this.set('errorMessage', 'Password must be filled');
          flag++;
          return;
        }

        if (flag === 0) {
          self.set('errorMessage', null);

          var _this$getProperties = this.getProperties('identification', 'password'),
              identification = _this$getProperties.identification,
              password = _this$getProperties.password;

          this.get('authObject').authenticate('authenticator:oauth2', identification.toLowerCase().trim(), password).then(function (result) {
            self.setSession();
          }).catch(function (reason) {
            if (self.get('authObject.session.isAuthenticated')) {
              self.setSession();
            } else {
              _this.set('errorMessage', reason.error || reason);

              Ember.run.later(self, function () {
                this.set('errorMessage', null);
              }, 2000);
            }
          });
        }
      },
      invalidateSession: function invalidateSession() {
        this.invalidateSession();
      },
      getSearch: function getSearch(value) {
        //prevent search if search form empty, both on left click and enter key
        var self = this;
        var flag2 = 0;

        if (Ember.isEmpty(this.get('search'))) {
          flag2++;
          return;
        }

        if (flag2 === 0) {
          this.getSearch(value);
        }
      }
    }
  });

  _exports.default = _default;
});