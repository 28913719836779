define("client/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    //relationships
    secretquestion: _emberData.default.belongsTo('secretquestion', {
      async: true
    }),
    country: _emberData.default.belongsTo('country', {
      async: true
    }),
    // attributes
    formuser: _emberData.default.attr('number'),
    euser: _emberData.default.attr('number'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    tagline: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    profilePicture: _emberData.default.attr('string'),
    confirmationKey: _emberData.default.attr('string'),
    // userType: DS.attr('string'),
    notification: _emberData.default.attr('array'),
    newNotification: _emberData.default.attr('boolean'),
    phone: _emberData.default.attr('string'),
    authEnable: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),

    /*postalCode: DS.attr('string'),
    address: DS.attr('string'),
    address1: DS.attr('string'),
    city: DS.attr('string'),
    state: DS.attr('string'),*/
    activeStatus: _emberData.default.attr('boolean'),
    type: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    fullname: _emberData.default.attr('string')
    /*/ computed
    fullName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
    */

  });

  _exports.default = _default;
});