define("client/pods/components/w3o-users-roles/login-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P3dN2Vo/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"form\"],[11,\"class\",\"form-inline\"],[3,\"action\",[[22,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"form-group form-group-sm\"],[9],[0,\"\\n\\t    \"],[7,\"label\"],[11,\"class\",\"sr-only\"],[11,\"for\",\"identification\"],[9],[0,\"Email address\"],[10],[0,\"\\n\\t    \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"id\",\"placeholder\",\"value\"],[\"email\",\"form-control\",\"identification\",\"Email\",[23,[\"identification\"]]]]],false],[0,\"\\n\\t\"],[10],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"form-group form-group-sm\"],[9],[0,\"\\n\\t    \"],[7,\"label\"],[11,\"class\",\"sr-only\"],[11,\"for\",\"password\"],[9],[0,\"Password\"],[10],[0,\"\\n\\t    \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"id\",\"placeholder\",\"value\"],[\"password\",\"form-control\",\"password\",\"Password\",[23,[\"password\"]]]]],false],[0,\"\\n\\t\"],[10],[0,\"\\n\\t\"],[7,\"button\"],[11,\"class\",\"btn btn-default btn-sm\"],[11,\"title\",\"Log In\"],[11,\"type\",\"submit\"],[9],[0,\"\\n\\t  \\t\"],[7,\"i\"],[11,\"class\",\"fa fa-arrow-right\"],[9],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[4,\"link-to\",[\"register\"],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\"],[11,\"class\",\"btn btn-default btn-sm\"],[11,\"title\",\"Sign Up\"],[11,\"type\",\"submit\"],[9],[0,\"\\n\\t  \\t\\t\"],[7,\"i\"],[11,\"class\",\"fa fa-arrow-up\"],[9],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3o-users-roles/login-bar/template.hbs"
    }
  });

  _exports.default = _default;
});