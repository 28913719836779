define("client/mixins/w3o-users-roles/create-edit-application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    icon: '',
    authObject: Ember.inject.service('session'),
    setupController: function setupController() {
      var controller = this.get('controller');
      var model = this.get('currentModel');
      controller.set('model', model);
      controller.set('dataURI', '');
      controller.set('isUploading', '');
    },
    queuePhoto: function queuePhoto(file) {
      var self = this;
      this.set('controller.isUploading', true);
      this.set('icon', file);
      file.read().then(function setPreviewUrl(dataURI) {
        self.set('controller.dataURI', dataURI);
      });
    },
    saveApp: function saveApp(model) {
      var flag = 0;
      var self = this;

      if (!/\S/.test(model.get('appName')) || !model.get('appName')) {
        Ember.$("#appForm").addClass("has-error");
        Ember.$('#glyphApp').removeClass('d-none');
        Ember.$('#appError').removeClass('d-none');
        model.set('appName', '');
        flag++;
      }

      if (!/\S/.test(model.get('routeName')) || !model.get('routeName')) {
        Ember.$("#routeForm").addClass("has-error");
        Ember.$('#glyphRoute').removeClass('d-none');
        Ember.$('#routeError').removeClass('d-none');
        model.set('routeName', '');
        flag++;
      }

      if (model.get('routeName') === 'd' && (!/\S/.test(model.get('appId')) || !model.get('appId'))) {
        Ember.$("#appIdForm").addClass("has-error");
        Ember.$('#glyphAppId').removeClass('d-none');
        Ember.$('#appIdError').removeClass('d-none');
        model.set('appId', '');
        flag++;
      }

      if (flag === 0) {
        var file = this.get('icon');

        if (this.get('controller.isUploading')) {
          var headers = {};
          headers['Authorization'] = "Bearer ".concat(this.get('authObject.data.authenticated.access_token'));
          file.upload('/api/v1/uploads/icon', {
            headers: headers
          }).then(function (response) {
            model.set('icon', response.body.path);
            return model.save().then(function () {
              if (self.get('appSetting.appId') === model.get('id')) {
                self.set('appSetting.resourceAccessEnabled', model.get('resourceAccessEnabled'));
              }

              self.transitionTo('users-roles.role-manager.index');
            });
          });
        } else {
          return model.save().then(function () {
            if (self.get('appSetting.appId') === model.get('id')) {
              self.set('appSetting.resourceAccessEnabled', model.get('resourceAccessEnabled'));
            }

            self.transitionTo('users-roles.role-manager.index');
          });
        }
      }

      Ember.$('#appForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#appForm").removeClass('has-error');
          Ember.$('#glyphApp').addClass('hidden');
          Ember.$('#appError').addClass('hidden');
        }
      });
      Ember.$('#routeForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#routeForm").removeClass('has-error');
          Ember.$('#glyphRoute').addClass('hidden');
          Ember.$('#routeError').addClass('hidden');
        }
      });
      Ember.$('#appIdForm input').blur(function () {
        if (this.value !== '') {
          Ember.$("#appIdForm").removeClass('has-error');
          Ember.$('#glyphAppId').addClass('hidden');
          Ember.$('#appIdError').addClass('hidden');
        }
      });
    }
  });

  _exports.default = _default;
});