define("client/mixins/home/get-list", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authObject: Ember.inject.service('session'),
    getProductlist: function getProductlist(page) {
      var self = this,
          controller = self.get('controller'),
          prevpage = 0,
          nextpage = 0,
          total = 0,
          span = 4;

      if (!page) {
        page = 0;
      } else {
        page = page - 1;
      }

      (0, _axios.default)({
        method: "POST",
        data: {
          isdelete: false,
          active: true
        },
        url: self.store.adapterFor('application').get('namespace') + '/item/getItemlist'
      }) //pagination in hot products
      .then(function (response) {
        controller.set('item', response.data.items.slice(span * page, span * (page + 1))); //total item content

        total = response.data.items.length;
        prevpage = page;

        if (prevpage <= 0) {
          controller.set('firstpage', true);
        } else {
          controller.set('firstpage', false);
        }

        nextpage = page + 2;

        if (nextpage > Math.ceil(total / span)) {
          nextpage = Math.ceil(total / span);
          controller.set('lastpage', true);
        } else {
          controller.set('lastpage', false);
        }

        controller.set('prevpage', prevpage);
        controller.set('nextpage', nextpage);
      }).catch(function (error) {
        console.log('Error ' + error);
      });
      /* axios({
           method: "GET",
           url: self.store.adapterFor('application').get('econmainHost') + '/formuploads/getFilePath?UUID=e3ca6f4f-9dcb-4cb8-95e6-98b19b038c60'
       })
       .then(function(response){
           console.log(response);
           controller.set('filePath',response.data[0].filePath );
       })
       .catch(function(error){
           console.log('Error ' + error);
       });*/
    }
  });

  _exports.default = _default;
});