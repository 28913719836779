define("client/pods/components/loading-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B3my/Bvw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"loading-bar\"],[11,\"style\",\"width: 54px; font-size: 13px;\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"rect1\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"rect2\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"rect3\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"rect4\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"rect5\"],[9],[10],[0,\"\\n        \"],[7,\"label\"],[9],[0,\"Loading...\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/loading-bar/template.hbs"
    }
  });

  _exports.default = _default;
});