define("client/services/file-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    url: null,
    paramNamespace: null,
    paramName: 'file',

    /**
     * ajax request type (method), by default it will be POST
     *
     * @property type
     */
    type: 'POST',
    upload: function upload(files, record) {
      var _this = this;

      var data;
      var url = Ember.get(this, 'url');
      var type = Ember.get(this, 'type');
      Ember.set(this, 'isUploading', true);
      Ember.$.each(files, function (index, value) {
        data = _this.setupFormData(value, record);

        _this.ajax(url, data, type).then(function (respData) {
          _this.didUpload(respData);
        });
      });
    },
    setupFormData: function setupFormData(file, extra) {
      var formData = new FormData();

      for (var prop in extra) {
        if (extra.hasOwnProperty(prop)) {
          formData.append(this.toNamespacedParam(prop), extra[prop]);
        }
      }

      formData.append(this.toNamespacedParam(this.paramName), file);
      return formData;
    },
    toNamespacedParam: function toNamespacedParam(name) {
      if (this.paramNamespace) {
        return this.paramNamespace + '[' + name + ']';
      }

      return name;
    },
    didUpload: function didUpload(data) {
      Ember.set(this, 'isUploading', false);
      this.trigger('didUpload', data);
    },
    didProgress: function didProgress(e) {
      e.percent = e.loaded / e.total * 100;
      this.trigger('progress', e);
    },
    ajax: function ajax(url, data, method) {
      var _this2 = this;

      var settings = {
        url: url,
        type: method || 'POST',
        contentType: false,
        processData: false,
        xhr: function xhr() {
          var xhr = Ember.$.ajaxSettings.xhr();

          xhr.upload.onprogress = function (e) {
            _this2.didProgress(e);
          };

          return xhr;
        },
        data: data
      };
      return this._ajax(settings);
    },
    _ajax: function _ajax(settings) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        settings.success = function (data) {
          Ember.run(null, resolve, data);
        };

        settings.error = function (jqXHR, textStatus, errorThrown) {
          Ember.run(null, reject, jqXHR);
        };

        Ember.$.ajax(settings);
      });
    }
  });

  _exports.default = _default;
});