define("client/pods/product/route", ["exports", "axios", "client/mixins/product/product-logic", "client/mixins/checkout/checkout-list", "client/mixins/address/address-logic", "client/mixins/search/search-page"], function (_exports, _axios, _productLogic, _checkoutList, _addressLogic, _searchPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_productLogic.default, _checkoutList.default, _addressLogic.default, _searchPage.default, {
    authObject: Ember.inject.service('session'),
    beforeModel: function beforeModel() {},
    model: function model(param) {
      return this.store.findRecord('item', param.id);
    },
    setupController: function setupController(controller, model) {
      var self = this;
      controller.setProperties({
        loadingPage: true,
        model: model,
        fillquantity: 1,
        review: null,
        pricefix: null,
        variantpricefix: null,
        disable: false,
        isvariant: false,
        star: null,
        choosenvariant: [],
        manyvariant: 0,
        notes: null,
        variantnotes: null,
        variant1: null,
        variant2: null,
        name: null,
        authObject: self.get('authObject'),
        changeaddress: 'changeaddress',
        domain: self.store.adapterFor('application').get('imagedomainHost')
      });

      if (self.get('authObject').get('isAuthenticated') === true) {
        controller.set('indicator', 'success');
      } else {
        controller.set('indicator', 'loginfirst');
      }

      self.store.queryRecord('item', {
        id: model.id
      }).then(function (items) {
        controller.set('isvariant', items.variant);
        controller.set('product', items);
      });
      self.getSearchInfo();
      self.getProductInfo(model.id);

      self._super();
    },
    actions: {
      prevList: function prevList() {
        this.prevList();
      },
      updateQty: function updateQty(qty) {
        this.updateQty(qty);
      },
      nextList: function nextList() {
        this.nextList();
      },
      pickvariant: function pickvariant(variantdetail) {
        this.pickvariant(variantdetail);
      },
      addtocart: function addtocart() {
        this.addtocart();
      },
      buynow: function buynow(product, qty) {
        this.buynow(product, qty);
      },
      wishlist: function wishlist(product, qty) {
        this.wishlist(product, qty);
      },
      gotocheckout: function gotocheckout() {
        this.transitionTo('cart');
      },
      chooseaddress: function chooseaddress(address) {
        this.chooseaddress(address);
      }
    }
  });

  _exports.default = _default;
});