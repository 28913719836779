define("client/pods/transaction/session-expired/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C6WBUL0i",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"container col-md-10 col-md-offset-2 col-lg-10 col-lg-offset-2 menu-container\"],[11,\"style\",\"padding-right: 30px; padding-left: 65px; margin-top: 30px;\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"loading-pane\"],[9],[0,\"\\n\\t    \"],[7,\"div\"],[11,\"class\",\"loading-message panel panel-default\"],[9],[0,\"\\n\\t     \\t\"],[7,\"h3\"],[9],[0,\"Your session already expired, please click below link to go to the Login page to login again.\"],[10],[7,\"br\"],[9],[10],[0,\"\\n\\t     \\t\"],[7,\"a\"],[11,\"role\",\"button\"],[3,\"action\",[[22,0,[]],\"goToStart\"]],[9],[0,\"\\n\\t     \\t\\t\"],[7,\"h4\"],[9],[0,\"Click Here\"],[10],[0,\"\\n\\t     \\t\"],[10],[0,\"\\n\\t    \"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/transaction/session-expired/template.hbs"
    }
  });

  _exports.default = _default;
});